export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: { input: any; output: any; }
  /** Additional data provided by the client */
  ClientAdditionalData: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  /** Name of the department */
  DepartmentName: { input: any; output: any; }
  /** E164-formatted phone number */
  E164PhoneNumber: { input: any; output: any; }
  /** Email Address */
  Email: { input: any; output: any; }
  /** Email Addresses comma separated */
  EmailsCommaSeparated: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
  /** A valid key-value pair (hash) */
  KeyValue: { input: any; output: any; }
  /** Name of the LocationType */
  LocationTypeName: { input: any; output: any; }
  /** Name of the payment type */
  PaymentTypeName: { input: any; output: any; }
  /** Name of the Service. */
  ServiceCodeName: { input: any; output: any; }
  /** Name of the site */
  SiteName: { input: any; output: any; }
  /** Name of the Storage Type. */
  StorageTypeName: { input: any; output: any; }
  /** Name of the Symptom. */
  SymptomName: { input: any; output: any; }
  TimeOfDay: { input: any; output: any; }
  /** Name of the vehicle class type */
  VehicleClassTypeName: { input: any; output: any; }
};

/** Autogenerated input type of AcceptProviderTermsConditions */
export type AcceptProviderTermsConditionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Enumerated Filters for Accounts */
export const AccountFilter = {
  /** All accounts */
  All: 'All',
  /** Accounts valid for Job creation */
  Create: 'Create'
} as const;

export type AccountFilter = typeof AccountFilter[keyof typeof AccountFilter];
export type AccountInput = {
  /** Account name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of AddAgentAlertJobSnooze */
export type AddAgentAlertJobSnoozeInput = {
  /** Agent Alert ID */
  agentAlertId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Snooze Duration in minutes */
  snoozeDuration: Scalars['Int']['input'];
  /** Snooze Reason */
  snoozeReason: Scalars['String']['input'];
};

/** Autogenerated input type of AddExistingSite */
export type AddExistingSiteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  provider: ProviderCompanyAddExistingSiteInput;
};

/** Autogenerated input type of AddInvoiceAdditionalItem */
export type AddInvoiceAdditionalItemInput = {
  /** The ID of the additional item that is being added to the invoice */
  additionalItemId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the invoice that the additional item is being added to */
  invoiceId: Scalars['ID']['input'];
  /** The price of the additional item that is being added to the invoice */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The quantity of the additional item that is being added to the invoice */
  quantity?: InputMaybe<Scalars['Float']['input']>;
  /** usage context of this mutation */
  usageContext?: InputMaybe<InvoiceUsageContextEnum>;
};

/** Autogenerated input type of AddInvoiceTransaction */
export type AddInvoiceTransactionInput = {
  /** The absolute value of the amount of the transaction */
  amount: Scalars['Float']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Date of the transaction */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /** The ID of the invoice that the transaction is being added to */
  invoiceId: Scalars['ID']['input'];
  /** A memo for the transaction */
  memo?: InputMaybe<Scalars['String']['input']>;
  /** The type of transaction that is being added to the invoice */
  paymentMethod?: InputMaybe<InvoiceTransactionPaymentMethodEnum>;
  /** The type of transaction that is being added to the invoice */
  transactionType: TransactionTypeEnum;
};

/** Autogenerated input type of AddJob */
export type AddJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job?: InputMaybe<AddJobJobInput>;
};

export type AddJobJobInput = {
  account?: InputMaybe<AccountInput>;
  /** @deprecated This is no longer used and the value is ignored */
  autoAssignDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  caller?: InputMaybe<JobCallerInput>;
  /** Case details */
  case?: InputMaybe<CaseInputType>;
  /** Case SSID */
  caseId?: InputMaybe<Scalars['ID']['input']>;
  claim?: InputMaybe<JobClaimInput>;
  client?: InputMaybe<ClientInput>;
  clientProgram?: InputMaybe<ClientProgramInput>;
  customer?: InputMaybe<JobCustomerInput>;
  customerLookupType?: InputMaybe<Scalars['String']['input']>;
  customerPayment?: InputMaybe<JobCustomerPaymentInput>;
  /** Date of Loss */
  dateOfLoss?: InputMaybe<Scalars['Date']['input']>;
  dispatcherId?: InputMaybe<Scalars['ID']['input']>;
  eta?: InputMaybe<JobEtaInputType>;
  /** Exposure Number represents the number of times a vehicle has had a claim */
  exposureNumber?: InputMaybe<Scalars['String']['input']>;
  location: AddJobJobLocationInput;
  notes?: InputMaybe<NotesInput>;
  partner?: InputMaybe<JobPartnerInput>;
  /** Deprecated: Please use paymentTypeName instead */
  paymentType?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  /** The name of the PaymentType used on the Job */
  paymentTypeName?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  priorityResponse?: InputMaybe<Scalars['Boolean']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  repairRefNumber?: InputMaybe<Scalars['String']['input']>;
  selectedPolicyDriver?: InputMaybe<SelectedPolicyDriverInput>;
  selectedPolicyVehicle?: InputMaybe<SelectedPolicyVehicleInput>;
  service: AddJobServiceInput;
  /** Deprecated, please use the createDraftJob mutation instead */
  status?: InputMaybe<AddJobStatusInput>;
  storageYard?: InputMaybe<StorageYardInput>;
  texts?: InputMaybe<JobTextsInput>;
  vehicle?: InputMaybe<VehicleInput>;
};

export type AddJobJobLocationInput = {
  /** Customer may optionally specify a separate contact who will be with the vehicle at the service location. */
  dropoffContact?: InputMaybe<ContactInput>;
  dropoffLocation?: InputMaybe<LocationInput>;
  /** Customer may optionally specify a separate contact who will be with the vehicle at the service location. */
  pickupContact?: InputMaybe<ContactInput>;
  serviceLocation: LocationInput;
};

export type AddJobServiceInput = {
  /** See below section for more information on Questions & Answers. */
  answers?: InputMaybe<Array<QuestionAnswerInput>>;
  /** Deprecated: please use classTypeName */
  classType?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  /** Class of partner vehicle needed to complete the job */
  classTypeName?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  /** See below section for more information on Services. */
  name: Scalars['ServiceCodeName']['input'];
  /** Question results for the service */
  questionResults?: InputMaybe<QuestionResultsInput>;
  /** Customer can optionally schedule the job for the future. Should be in ISO 8601 format. */
  scheduledFor?: InputMaybe<Scalars['String']['input']>;
  /** Name of the Storage Site */
  storageSite?: InputMaybe<Scalars['SiteName']['input']>;
  /** Deprecated: please use storageTypeName */
  storageType?: InputMaybe<Scalars['StorageTypeName']['input']>;
  /** See below section for more information on Storage Types. */
  storageTypeName?: InputMaybe<Scalars['StorageTypeName']['input']>;
  /** See below section for more information on Symptoms. */
  symptom?: InputMaybe<Scalars['SymptomName']['input']>;
  /** Whether or not to store the customers vehicle */
  willStore?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Override the initial JobStatus */
export const AddJobStatusInput = {
  /** Draft */
  Draft: 'Draft'
} as const;

export type AddJobStatusInput = typeof AddJobStatusInput[keyof typeof AddJobStatusInput];
/** Autogenerated input type of AddRate */
export type AddRateInput = {
  /** Why the rate is changing */
  changeReason: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Rate ID to copy from */
  copyFromRateId?: InputMaybe<Scalars['ID']['input']>;
  /** Attributes for the new rate */
  rate: RateInput;
};

/** Autogenerated input type of AddSiteToExistingProvider */
export type AddSiteToExistingProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  provider: ProviderCompanyAddSiteToExistingProviderInput;
};

/** Filter for additional items */
export const AdditionalItemFilterEnum = {
  /** Exclude release fee additional items */
  ExcludeReleaseFees: 'ExcludeReleaseFees',
  /** Only return release fee additional items */
  ReleaseFeesOnly: 'ReleaseFeesOnly'
} as const;

export type AdditionalItemFilterEnum = typeof AdditionalItemFilterEnum[keyof typeof AdditionalItemFilterEnum];
/** Filters for Addon services. */
export type AddonsFiltersInput = {
  /** rates related filters */
  rates?: InputMaybe<RatesFiltersInput>;
};

/** Autogenerated input type of AgentCallRecording */
export type AgentCallRecordingInput = {
  /** Agent Call Recording Input */
  agentCallRecording: CTIAgentCallRecordingInputType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of AgentChangeExtension */
export type AgentChangeExtensionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ctiAgentChangeExtension: CtiAgentChangeExtensionInput;
};

/** Autogenerated input type of AgentEventReceipt */
export type AgentEventReceiptInput = {
  agentEventReceipt: AgentEventReceiptScreenpopInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AgentEventReceiptScreenpopInput = {
  ctiAgentEventId: Scalars['Int']['input'];
  status: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
};

/** Autogenerated input type of AgentLogin */
export type AgentLoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ctiAgentLogin: CtiAgentLoginInput;
};

/** The Cti Status of an agent */
export const AgentStatusEnum = {
  /** Available */
  available: 'available',
  /** Break */
  break: 'break',
  /** Busy */
  busy: 'busy',
  /** Login */
  login: 'login',
  /** Lunch */
  lunch: 'lunch',
  /** Offline */
  offline: 'offline'
} as const;

export type AgentStatusEnum = typeof AgentStatusEnum[keyof typeof AgentStatusEnum];
/** Autogenerated input type of AgentStatus */
export type AgentStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ctiAgentStatus: CtiAgentStatusInput;
};

export type AnswersFiltersInput = {
  useServiceFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of ApproveInvoice */
export type ApproveInvoiceInput = {
  /** SSID of approving user. Required if authenticated as an application */
  approverId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being rejected. */
  id: Scalars['ID']['input'];
};

export type AreaInputType = {
  from: LatitudeLongitudeInput;
  to: LatitudeLongitudeInput;
};

/** Autogenerated input type of AssignDispatcher */
export type AssignDispatcherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job: AssignDispatcherJobInput;
};

export type AssignDispatcherJobInput = {
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of AssociateJob */
export type AssociateJobInput = {
  associateJob: AssociateJobScreenpopInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AssociateJobScreenpopInput = {
  ctiAgentEventId: Scalars['Int']['input'];
  jobId: Scalars['Int']['input'];
};

export type AttachedDocumentInput = {
  document: Scalars['JSON']['input'];
  type: DocumentTypeType;
};

/** Auction Ineligibility Reason */
export const AuctionIneligibilityReason = {
  /** Auto Swoop Dispatch Disabled */
  auto_swoop_dispatch_disabled: 'auto_swoop_dispatch_disabled',
  /** Class Type Unauctionable */
  class_type_unauctionable: 'class_type_unauctionable',
  /** Drop Location Unauctionable */
  drop_location_unauctionable: 'drop_location_unauctionable',
  /** Has Equipment For Non Tow */
  has_equipment_for_non_tow: 'has_equipment_for_non_tow',
  /** Job Does Not Support Auto Assign */
  job_does_not_support_auto_assign: 'job_does_not_support_auto_assign',
  /** Job Fleet Company Type Not Eligible For Auction */
  job_fleet_company_type_not_eligible_for_auction: 'job_fleet_company_type_not_eligible_for_auction',
  /** Max Bidders Less Than One */
  max_bidders_less_than_one: 'max_bidders_less_than_one',
  /** Missing Class Type */
  missing_class_type: 'missing_class_type',
  /** Missing Equipment For Tow */
  missing_equipment_for_tow: 'missing_equipment_for_tow',
  /** Motor Club Transfer Requested */
  motor_club_transfer_requested: 'motor_club_transfer_requested',
  /** Motor Club Transfer Unsuccessful */
  motor_club_transfer_unsuccessful: 'motor_club_transfer_unsuccessful',
  /** No Zip Code Available */
  no_zip_code_available: 'no_zip_code_available',
  /** Not A Fleet Managed Job */
  not_a_fleet_managed_job: 'not_a_fleet_managed_job',
  /** Partner Pre Assigned To Scheduled Job */
  partner_pre_assigned_to_scheduled_job: 'partner_pre_assigned_to_scheduled_job',
  /** Scheduled Job */
  scheduled_job: 'scheduled_job',
  /** Service Code Missing */
  service_code_missing: 'service_code_missing',
  /** Service Code Not Allowed For Feature Auto Assign */
  service_code_not_allowed_for_feature_auto_assign: 'service_code_not_allowed_for_feature_auto_assign',
  /** Service Code Not Allowed For Feature Auto Assign Road And Custom */
  service_code_not_allowed_for_feature_auto_assign_road_and_custom: 'service_code_not_allowed_for_feature_auto_assign_road_and_custom',
  /** Service Code Unavailable */
  service_code_unavailable: 'service_code_unavailable',
  /** Service Location Missing */
  service_location_missing: 'service_location_missing',
  /** Service Location Unauctionable */
  service_location_unauctionable: 'service_location_unauctionable',
  /** Service Location With Missing Coordinates */
  service_location_with_missing_coordinates: 'service_location_with_missing_coordinates',
  /** Status Unauctionable */
  status_unauctionable: 'status_unauctionable',
  /** Vehicle Has Unlisted Trailer */
  vehicle_has_unlisted_trailer: 'vehicle_has_unlisted_trailer'
} as const;

export type AuctionIneligibilityReason = typeof AuctionIneligibilityReason[keyof typeof AuctionIneligibilityReason];
/** Auction Result */
export const AuctionResult = {
  /** Bidding Not Enabled */
  bidding_not_enabled: 'bidding_not_enabled',
  /** Internal Error */
  internal_error: 'internal_error',
  /** Job Assigned Manually */
  job_assigned_manually: 'job_assigned_manually',
  /** Job Canceled */
  job_canceled: 'job_canceled',
  /** Job Details Changed */
  job_details_changed: 'job_details_changed',
  /** Job Ineligible For Auto Assign */
  job_ineligible_for_auto_assign: 'job_ineligible_for_auto_assign',
  /** Job Ineligible For Digital Dispatch */
  job_ineligible_for_digital_dispatch: 'job_ineligible_for_digital_dispatch',
  /** Job Referred */
  job_referred: 'job_referred',
  /** Job Scheduled */
  job_scheduled: 'job_scheduled',
  /** No Candidates Found */
  no_candidates_found: 'no_candidates_found',
  /** No Elgible Sites Or Vehicles In Eta */
  no_elgible_sites_or_vehicles_in_eta: 'no_elgible_sites_or_vehicles_in_eta',
  /** No Eligible Candidates */
  no_eligible_candidates: 'no_eligible_candidates',
  /** No Live Trucks Or Sites In Range */
  no_live_trucks_or_sites_in_range: 'no_live_trucks_or_sites_in_range',
  /** No Winner Bid */
  no_winner_bid: 'no_winner_bid',
  /** Oon Dispatch Selected */
  oon_dispatch_selected: 'oon_dispatch_selected',
  /** Pickup Location Changed */
  pickup_location_changed: 'pickup_location_changed',
  /** Stuck Auction */
  stuck_auction: 'stuck_auction'
} as const;

export type AuctionResult = typeof AuctionResult[keyof typeof AuctionResult];
/** Auction Status */
export const AuctionStatus = {
  /** Canceled */
  Canceled: 'Canceled',
  /** Ineligible */
  Ineligible: 'Ineligible',
  /** Live */
  Live: 'Live',
  /** New */
  New: 'New',
  /** Scheduled */
  Scheduled: 'Scheduled',
  /** Successful */
  Successful: 'Successful',
  /** Unsuccessful */
  Unsuccessful: 'Unsuccessful'
} as const;

export type AuctionStatus = typeof AuctionStatus[keyof typeof AuctionStatus];
/** Authorization form field data for coverage lookup */
export type AuthorizationResponseInput = {
  /** Authorization form field label */
  label: Scalars['String']['input'];
  /** Authorization form field value */
  value: Scalars['String']['input'];
};

/** An enum representing the statuses of a background check. */
export const BackgroundCheckStatusEnum = {
  /** Audited */
  Audited: 'Audited',
  /** Confirmed Removal */
  ConfirmedRemoval: 'ConfirmedRemoval',
  /** Does Not Meet Requirements */
  DoesNotMeetRequirements: 'DoesNotMeetRequirements',
  /** Expired */
  Expired: 'Expired',
  /** In Review */
  InReview: 'InReview',
  /** No Longer Employed */
  NoLongerEmployed: 'NoLongerEmployed',
  /** Not Submitted */
  NotSubmitted: 'NotSubmitted',
  /** Pending */
  Pending: 'Pending'
} as const;

export type BackgroundCheckStatusEnum = typeof BackgroundCheckStatusEnum[keyof typeof BackgroundCheckStatusEnum];
/** Bid Status */
export const BidStatus = {
  /** The bid was received but the partner lost the auction */
  AutoRejected: 'AutoRejected',
  /** The job was manually assigned to a partner by Swoop and the auction was canceled */
  Canceled: 'Canceled',
  /** The auto_dispatch ended before the partner submitted the bid */
  Expired: 'Expired',
  /** Initial State */
  New: 'New',
  /** Partner rejected the job */
  ProviderRejected: 'ProviderRejected',
  /** Bid created but partner has not interacted with it; auction is LIVE */
  Requested: 'Requested',
  /** A bid was submitted and awaiting auction end */
  Submitted: 'Submitted',
  /** Partner is the successful bidder */
  Won: 'Won'
} as const;

export type BidStatus = typeof BidStatus[keyof typeof BidStatus];
/** Enumerated billing types */
export const BillingType = {
  /** Automated Clearing House Network transfer */
  ACH: 'ACH',
  /** Virtual Credit Card payment */
  VCC: 'VCC'
} as const;

export type BillingType = typeof BillingType[keyof typeof BillingType];
export type CTCInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** This is agent call recording start/stop while collecting credit card info */
export type CTIAgentCallRecordingInputType = {
  /** Event start/stop time */
  eventTime: Scalars['DateTime']['input'];
  /** Job ID */
  jobId: Scalars['Int']['input'];
  /** Event start/stop */
  operation: Scalars['String']['input'];
};

/** Transitions for a call recording */
export const CallRecordingTransitionEnum = {
  /** Start the call recording */
  Start: 'Start',
  /** Stop the call recording */
  Stop: 'Stop'
} as const;

export type CallRecordingTransitionEnum = typeof CallRecordingTransitionEnum[keyof typeof CallRecordingTransitionEnum];
export const CandidateDispatchModeEnum = {
  /** auto */
  auto: 'auto',
  /** direct */
  direct: 'direct',
  /** manual */
  manual: 'manual',
  /** release */
  release: 'release',
  /** search */
  search: 'search'
} as const;

export type CandidateDispatchModeEnum = typeof CandidateDispatchModeEnum[keyof typeof CandidateDispatchModeEnum];
/** An enumeration of candidate statuses. */
export const CandidateStatusEnum = {
  /** cost_calculation_error */
  costCalculationError: 'costCalculationError',
  /** cost_too_high */
  costTooHigh: 'costTooHigh',
  /** eligible */
  eligible: 'eligible',
  /** eta_missing */
  etaMissing: 'etaMissing',
  /** eta_too_high */
  etaTooHigh: 'etaTooHigh',
  /** quality_too_high */
  qualityTooHigh: 'qualityTooHigh',
  /** quality_too_low */
  qualityTooLow: 'qualityTooLow',
  /** reassigned */
  reassigned: 'reassigned',
  /** score_error */
  scoreError: 'scoreError',
  /** zero_invoice_cost_error */
  zeroInvoiceCostError: 'zeroInvoiceCostError'
} as const;

export type CandidateStatusEnum = typeof CandidateStatusEnum[keyof typeof CandidateStatusEnum];
export type CapabilitiesFiltersInput = {
  /** Filter active capabilities */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include potential capabilities. May not be used with active. Must be used with type */
  present?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter service / addon capabilities */
  type?: InputMaybe<RateAgreementCapabilityType>;
};

export type CapabilityInput = {
  classTypeId: Scalars['ID']['input'];
  equipmentId?: InputMaybe<Scalars['ID']['input']>;
  serviceCodeId: Scalars['ID']['input'];
};

/** Attributes for creating or updating a case */
export type CaseInputType = {
  /** Additional data provided by the client */
  clientAdditionalData?: InputMaybe<Scalars['ClientAdditionalData']['input']>;
};

/** Case Status */
export const CaseStatusEnum = {
  /** Canceled */
  Canceled: 'Canceled',
  /** Closed */
  Closed: 'Closed',
  /** Deleted */
  Deleted: 'Deleted',
  /** Open */
  Open: 'Open'
} as const;

export type CaseStatusEnum = typeof CaseStatusEnum[keyof typeof CaseStatusEnum];
export type ChangeProviderInput = {
  account?: InputMaybe<AccountInput>;
  /** @deprecated This is no longer used and the value is ignored */
  autoAssignDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  caller?: InputMaybe<JobCallerInput>;
  /** Case details */
  case?: InputMaybe<CaseInputType>;
  /** Case SSID */
  caseId?: InputMaybe<Scalars['ID']['input']>;
  claim?: InputMaybe<JobClaimInput>;
  client?: InputMaybe<ClientInput>;
  clientProgram?: InputMaybe<ClientProgramInput>;
  customer?: InputMaybe<JobCustomerInput>;
  customerLookupType?: InputMaybe<Scalars['String']['input']>;
  customerPayment?: InputMaybe<JobCustomerPaymentInput>;
  /** Date of Loss */
  dateOfLoss?: InputMaybe<Scalars['Date']['input']>;
  dispatcherId?: InputMaybe<Scalars['ID']['input']>;
  eta?: InputMaybe<JobEtaInputType>;
  /** Exposure Number represents the number of times a vehicle has had a claim */
  exposureNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<JobLocationInput>;
  newRescueProviderId: Scalars['ID']['input'];
  notes?: InputMaybe<NotesInput>;
  oldRescueProviderId: Scalars['ID']['input'];
  partner?: InputMaybe<JobPartnerInput>;
  /** Deprecated: Please use paymentTypeName instead */
  paymentType?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  /** The name of the PaymentType used on the Job */
  paymentTypeName?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  priorityResponse?: InputMaybe<Scalars['Boolean']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  repairRefNumber?: InputMaybe<Scalars['String']['input']>;
  selectedPolicyDriver?: InputMaybe<SelectedPolicyDriverInput>;
  selectedPolicyVehicle?: InputMaybe<SelectedPolicyVehicleInput>;
  /** Service details */
  service?: InputMaybe<ServiceInput>;
  storageYard?: InputMaybe<StorageYardInput>;
  texts?: InputMaybe<JobTextsInput>;
  vehicle?: InputMaybe<VehicleInput>;
};

/** Autogenerated input type of ChangeProviderToJob */
export type ChangeProviderToJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job: ChangeProviderInput;
};

/** Autogenerated input type of ChooseCandidate */
export type ChooseCandidateInput = {
  /** Billing type being used on the job */
  billingType: BillingType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Override for the dispatch email on the rate agreement */
  dispatchEmail?: InputMaybe<Scalars['String']['input']>;
  /** Number of minutes bid for the ETA for a manual assign candidate */
  etaMinutes?: InputMaybe<Scalars['Int']['input']>;
  /** SSID of the candidate being chosen. One of id or rateAgreementId must be passed */
  id: Scalars['ID']['input'];
  /** Invoice amount for completed out of network jobs */
  invoiceTotal?: InputMaybe<Scalars['Float']['input']>;
  /** Invoice amount to be paid for unsuccessful out of network jobs */
  partialAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type ClaimNumberInput = {
  /** Claim number */
  claimNumber: Scalars['String']['input'];
};

/** Criteria to choose the expected ClassType to fulfill any Job. */
export type ClassTypeLookupInput = {
  /** Service Code */
  serviceCode: Scalars['String']['input'];
  /** Total Length of the vehicle */
  totalLengthRange?: InputMaybe<Scalars['String']['input']>;
  /** Total Weight of the vehicle */
  totalWeightRange?: InputMaybe<Scalars['String']['input']>;
};

/** Predefined list of vehicle class types */
export const ClassTypes = {
  /** Heavy Duty */
  HeavyDuty: 'HeavyDuty',
  /** Light Duty */
  LightDuty: 'LightDuty',
  /** Medium Duty */
  MediumDuty: 'MediumDuty'
} as const;

export type ClassTypes = typeof ClassTypes[keyof typeof ClassTypes];
/** Autogenerated input type of ClickToCall */
export type ClickToCallInput = {
  clickToCall: CTCInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** An enumeration of client eligibility policies. */
export const ClientEligibilityPolicyEnum = {
  /** The list of clients that is allowed to use this rate agreement. */
  ALLOW: 'ALLOW',
  /** The list of clients is blocked from using this rate agreement. */
  BLOCK: 'BLOCK'
} as const;

export type ClientEligibilityPolicyEnum = typeof ClientEligibilityPolicyEnum[keyof typeof ClientEligibilityPolicyEnum];
/** Filter to limiting jobs based on features available to a client */
export const ClientFeature = {
  /** Accident Tow jobs for companies participating in Accident Management service */
  AccidentManagement: 'AccidentManagement'
} as const;

export type ClientFeature = typeof ClientFeature[keyof typeof ClientFeature];
export type ClientInput = {
  /** Name of the client department assigned to the job */
  department?: InputMaybe<Scalars['DepartmentName']['input']>;
  /** Name of the client site assigned to the job */
  site?: InputMaybe<Scalars['SiteName']['input']>;
};

export type ClientProgramInput = {
  /** Client program code for the coverage */
  code: Scalars['String']['input'];
};

/** Autogenerated input type of CmwCreateCustomerPayment */
export type CmwCreateCustomerPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  jobCustomerPayment: CreateJobCustomerPaymentInput;
};

export type CompanyCustomerExperienceInput = {
  autoCompleteJobsTime?: InputMaybe<Scalars['Int']['input']>;
  autoDeleteDraftJobs?: InputMaybe<Scalars['Boolean']['input']>;
  autoDeleteDraftJobsHours?: InputMaybe<Scalars['Int']['input']>;
  autoOnsiteJobsTime?: InputMaybe<Scalars['Int']['input']>;
  completeConfirmationAlertThreshold?: InputMaybe<Scalars['Int']['input']>;
  delayedDispatchThreshold?: InputMaybe<Scalars['Int']['input']>;
  etaAlertThreshold?: InputMaybe<Scalars['Int']['input']>;
  maxReassignThreshold?: InputMaybe<Scalars['Int']['input']>;
  onSiteConfirmationDelay?: InputMaybe<Scalars['Int']['input']>;
  serviceRecoveryThreshold?: InputMaybe<Scalars['Int']['input']>;
  winbackNpsScore?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyInput = {
  id: Scalars['ID']['input'];
};

export type CompanyInvoiceChargeFeeInput = {
  /** Invoice charge fee payout interval */
  payoutInterval?: InputMaybe<PayoutIntervalEnum>;
  /** Invoice charge fee percentage */
  percentage?: InputMaybe<Scalars['Float']['input']>;
  /** Invoice charge fee fixed value */
  transactionFee?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyNamePostalCodeInput = {
  /** Company name */
  companyName: Scalars['String']['input'];
  /** Company Postal Code */
  postalCode: Scalars['String']['input'];
};

export type CompanyNetworkManagerInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Rate Limit for a given company */
export const CompanyRateLimit = {
  /** Large */
  Large: 'Large',
  /** Medium */
  Medium: 'Medium',
  /** Small */
  Small: 'Small',
  /** Unlimited */
  Unlimited: 'Unlimited',
  /** X-Large */
  XLarge: 'XLarge',
  /** XX-Large */
  XXLarge: 'XXLarge'
} as const;

export type CompanyRateLimit = typeof CompanyRateLimit[keyof typeof CompanyRateLimit];
/** Company Subtype */
export const CompanySubtype = {
  /** FleetInHouse */
  FleetInHouse: 'FleetInHouse',
  /** FleetManaged */
  FleetManaged: 'FleetManaged',
  /** FleetMotorClub */
  FleetMotorClub: 'FleetMotorClub',
  /** Manager */
  Manager: 'Manager',
  /** Partner */
  Partner: 'Partner'
} as const;

export type CompanySubtype = typeof CompanySubtype[keyof typeof CompanySubtype];
/** Company Type */
export const CompanyType = {
  /** FleetCompany */
  ClientCompany: 'ClientCompany',
  /** RescueCompany */
  PartnerCompany: 'PartnerCompany',
  /** SuperCompany */
  SuperCompany: 'SuperCompany'
} as const;

export type CompanyType = typeof CompanyType[keyof typeof CompanyType];
/** Autogenerated input type of ConsumerCreateJobTerminationRequest */
export type ConsumerCreateJobTerminationRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['ID']['input'];
};

export type ContactInput = {
  /** Deprecated: please use name instead. */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** First and last name of the customer combined. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the contact. */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
};

/** Autogenerated input type of CopyRates */
export type CopyRatesInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  serviceCodeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

/** Coverage File Input */
export type CoverageFileInput = {
  /** Coverage File Data */
  data?: InputMaybe<Scalars['String']['input']>;
  /** Coverage File Name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Coverage File Input */
export type CoverageFileRequiredInputFields = {
  /** Coverage File Data */
  data: Scalars['String']['input'];
  /** Coverage File Name */
  name: Scalars['String']['input'];
};

/** PCC Coverage result */
export const CoverageResultEnum = {
  /** Covered */
  Covered: 'Covered',
  /** Failed */
  Failed: 'Failed',
  /** Missing Fields */
  MissingFields: 'MissingFields',
  /** Not Covered */
  NotCovered: 'NotCovered',
  /** Partially Covered */
  PartiallyCovered: 'PartiallyCovered'
} as const;

export type CoverageResultEnum = typeof CoverageResultEnum[keyof typeof CoverageResultEnum];
/** Autogenerated input type of CreateAttachedDocument */
export type CreateAttachedDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  file: AttachedDocumentInput;
};

/** Autogenerated input type of CreateCandidate */
export type CreateCandidateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the job to create the candidate for. */
  jobId: Scalars['ID']['input'];
  /** SSID of the rate agreement to assign to the candidate. */
  rateAgreementId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateClientCompanyRequiredEquipmentRule */
export type CreateClientCompanyRequiredEquipmentRuleInput = {
  /** Client Company SSID */
  clientCompanyId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Equipment Name */
  equipment: Scalars['String']['input'];
  /** Vehicle Make Name */
  make?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Model Name */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Service Code Group SSID */
  serviceCodeGroupId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateClientMobileJob */
export type CreateClientMobileJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Job data */
  job: JobInput;
};

/** Autogenerated input type of CreateClientProgram */
export type CreateClientProgramInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Program Code */
  code: Scalars['String']['input'];
  /** Client Company SSID */
  companyId: Scalars['ID']['input'];
  /** Coverage File */
  coverageFile?: InputMaybe<CoverageFileInput>;
  /** Discretionary Miles */
  discretionaryMiles?: InputMaybe<Scalars['Int']['input']>;
  /** Manual Selection */
  manualSelection?: InputMaybe<Scalars['Boolean']['input']>;
  /** Client Program Name */
  name: Scalars['String']['input'];
  /** Client Program Identifier */
  programId: Scalars['String']['input'];
  /** Rank */
  rank?: InputMaybe<Scalars['Int']['input']>;
  /** VCC Limit */
  vccLimit?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated input type of CreateCompany */
export type CreateCompanyInput = {
  accountingEmail: Scalars['Email']['input'];
  billingType?: InputMaybe<BillingType>;
  clientCode?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  compliance?: InputMaybe<Scalars['Boolean']['input']>;
  currency: Currency;
  dispatchEmail?: InputMaybe<Scalars['Email']['input']>;
  distanceUnit: DistanceUnit;
  inHouse?: InputMaybe<Scalars['Boolean']['input']>;
  language: LanguageEnum;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationInput;
  name: Scalars['String']['input'];
  networkManager?: InputMaybe<CompanyNetworkManagerInput>;
  phone: Scalars['E164PhoneNumber']['input'];
  requiresApprovedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ssoRedirectLink?: InputMaybe<Scalars['String']['input']>;
  subscriptionStatusEnum?: InputMaybe<SubscriptionStatusEnum>;
  supportEmail?: InputMaybe<Scalars['Email']['input']>;
  type: CompanyType;
};

/** Autogenerated input type of CreateCompanyUser */
export type CreateCompanyUserInput = {
  /** List of clients this is an approved user for */
  approvedClientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Company ID */
  companyId: Scalars['ID']['input'];
  /** CTI eligibility */
  ctiEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** First name */
  firstName: Scalars['String']['input'];
  /** When to send job status emails to the user */
  jobStatusEmailFrequency?: InputMaybe<JobStatusEmailFrequencyEnum>;
  /** What job status emails to send to the user */
  jobStatusEmailPreferences?: InputMaybe<JobStatusEmailPreferencesInput>;
  /** Last name */
  lastName: Scalars['String']['input'];
  /** Subscribed notification event settings for the user */
  notificationSettings?: InputMaybe<Array<NotificationSettingInput>>;
  /** Secondary phone number or pager */
  pager?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Sites the user has access to */
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Username */
  userName?: InputMaybe<Scalars['String']['input']>;
  /** Roles */
  userRoles?: InputMaybe<Array<RoleType>>;
  /** Venmo username */
  venmoUsername?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateCoupon */
export type CreateCouponInput = {
  /** The client code of the company */
  clientCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the coupon */
  description: Scalars['String']['input'];
  /** The effective date of the coupon */
  effectiveDate: Scalars['DateTime']['input'];
  /** The expiration date of the coupon */
  expirationDate: Scalars['DateTime']['input'];
  /** The external identifier of the coupon */
  externalId: Scalars['String']['input'];
};

/** Autogenerated input type of CreateCoverageNotes */
export type CreateCoverageNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
  programCode: Scalars['String']['input'];
  service?: InputMaybe<Scalars['String']['input']>;
  visibleToProvider: Scalars['Boolean']['input'];
};

/** Autogenerated input type of CreateCustomerPayment */
export type CreateCustomerPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  jobCustomerPayment: CreateJobCustomerPaymentInput;
};

/** Autogenerated input type of CreateDemoJob */
export type CreateDemoJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateDocument */
export type CreateDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  document: DocumentInput;
};

/** Autogenerated input type of CreateDraftJob */
export type CreateDraftJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job?: InputMaybe<CreateDraftJobJobInput>;
};

export type CreateDraftJobJobInput = {
  account?: InputMaybe<AccountInput>;
  /** @deprecated This is no longer used and the value is ignored */
  autoAssignDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  caller?: InputMaybe<JobCallerInput>;
  /** Case details */
  case?: InputMaybe<CaseInputType>;
  /** Case SSID */
  caseId?: InputMaybe<Scalars['ID']['input']>;
  claim?: InputMaybe<JobClaimInput>;
  client?: InputMaybe<ClientInput>;
  clientProgram?: InputMaybe<ClientProgramInput>;
  customer?: InputMaybe<JobCustomerInput>;
  customerLookupType?: InputMaybe<Scalars['String']['input']>;
  customerPayment?: InputMaybe<JobCustomerPaymentInput>;
  /** Date of Loss */
  dateOfLoss?: InputMaybe<Scalars['Date']['input']>;
  dispatcherId?: InputMaybe<Scalars['ID']['input']>;
  eta?: InputMaybe<JobEtaInputType>;
  /** Exposure Number represents the number of times a vehicle has had a claim */
  exposureNumber?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<JobLocationInput>;
  notes?: InputMaybe<NotesInput>;
  partner?: InputMaybe<JobPartnerInput>;
  /** Deprecated: Please use paymentTypeName instead */
  paymentType?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  /** The name of the PaymentType used on the Job */
  paymentTypeName?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  priorityResponse?: InputMaybe<Scalars['Boolean']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  repairRefNumber?: InputMaybe<Scalars['String']['input']>;
  selectedPolicyDriver?: InputMaybe<SelectedPolicyDriverInput>;
  selectedPolicyVehicle?: InputMaybe<SelectedPolicyVehicleInput>;
  /** Service details */
  service?: InputMaybe<ServiceInput>;
  storageYard?: InputMaybe<StorageYardInput>;
  texts?: InputMaybe<JobTextsInput>;
  vehicle?: InputMaybe<VehicleInput>;
};

/** Autogenerated input type of CreateEventLimit */
export type CreateEventLimitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Company SSID */
  companyId: Scalars['ID']['input'];
  /** Time Period in Days */
  days: Scalars['Int']['input'];
  /** Limit covered events */
  limit: Scalars['Int']['input'];
  /** Program Code */
  programCode: Scalars['String']['input'];
  /** Service Name */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** Target */
  target: EventLimitTarget;
};

/** Autogenerated input type of Create */
export type CreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  provider: ProviderCompanyCreateInput;
};

/** Autogenerated input type of CreateInvoiceReport */
export type CreateInvoiceReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The company on which to run this report */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter invoices before this date in ISO8601 format */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter invoices before this date in ISO8601 format */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** A unique identifier which will be returned with the subscription */
  uuid: Scalars['String']['input'];
};

export type CreateJobCustomerPaymentInput = {
  collectedBy?: InputMaybe<Scalars['String']['input']>;
  customerRate?: InputMaybe<JobCustomerRateResponseInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['ID']['input'];
  paymentAmount: Scalars['Float']['input'];
  stripeToken: Scalars['String']['input'];
};

/** Autogenerated input type of CreateJob */
export type CreateJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job?: InputMaybe<CreateJobJobInput>;
};

export type CreateJobJobInput = {
  account?: InputMaybe<AccountInput>;
  /** @deprecated This is no longer used and the value is ignored */
  autoAssignDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  caller?: InputMaybe<JobCallerInput>;
  /** Case details */
  case?: InputMaybe<CaseInputType>;
  /** Case SSID */
  caseId?: InputMaybe<Scalars['ID']['input']>;
  claim?: InputMaybe<JobClaimInput>;
  client?: InputMaybe<ClientInput>;
  clientProgram?: InputMaybe<ClientProgramInput>;
  customer?: InputMaybe<JobCustomerInput>;
  customerLookupType?: InputMaybe<Scalars['String']['input']>;
  customerPayment?: InputMaybe<JobCustomerPaymentInput>;
  /** Date of Loss */
  dateOfLoss?: InputMaybe<Scalars['Date']['input']>;
  dispatcherId?: InputMaybe<Scalars['ID']['input']>;
  eta?: InputMaybe<JobEtaInputType>;
  /** Exposure Number represents the number of times a vehicle has had a claim */
  exposureNumber?: InputMaybe<Scalars['String']['input']>;
  location: CreateJobJobLocationInput;
  notes?: InputMaybe<NotesInput>;
  partner?: InputMaybe<JobPartnerInput>;
  /** Deprecated: Please use paymentTypeName instead */
  paymentType?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  /** The name of the PaymentType used on the Job */
  paymentTypeName?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  priorityResponse?: InputMaybe<Scalars['Boolean']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  repairRefNumber?: InputMaybe<Scalars['String']['input']>;
  selectedPolicyDriver?: InputMaybe<SelectedPolicyDriverInput>;
  selectedPolicyVehicle?: InputMaybe<SelectedPolicyVehicleInput>;
  service: CreateJobServiceInput;
  /** Deprecated, please use the createDraftJob mutation instead */
  status?: InputMaybe<CreateJobStatusInput>;
  storageYard?: InputMaybe<StorageYardInput>;
  texts?: InputMaybe<JobTextsInput>;
  vehicle?: InputMaybe<VehicleInput>;
};

export type CreateJobJobLocationInput = {
  /** Customer may optionally specify a separate contact who will be with the vehicle at the service location. */
  dropoffContact?: InputMaybe<ContactInput>;
  dropoffLocation?: InputMaybe<LocationInput>;
  /** Customer may optionally specify a separate contact who will be with the vehicle at the service location. */
  pickupContact?: InputMaybe<ContactInput>;
  serviceLocation: LocationInput;
};

export type CreateJobServiceInput = {
  /** See below section for more information on Questions & Answers. */
  answers?: InputMaybe<Array<QuestionAnswerInput>>;
  /** Deprecated: please use classTypeName */
  classType?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  /** Class of partner vehicle needed to complete the job */
  classTypeName?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  /** See below section for more information on Services. */
  name: Scalars['ServiceCodeName']['input'];
  /** Question results for the service */
  questionResults?: InputMaybe<QuestionResultsInput>;
  /** Customer can optionally schedule the job for the future. Should be in ISO 8601 format. */
  scheduledFor?: InputMaybe<Scalars['String']['input']>;
  /** Name of the Storage Site */
  storageSite?: InputMaybe<Scalars['SiteName']['input']>;
  /** Deprecated: please use storageTypeName */
  storageType?: InputMaybe<Scalars['StorageTypeName']['input']>;
  /** See below section for more information on Storage Types. */
  storageTypeName?: InputMaybe<Scalars['StorageTypeName']['input']>;
  /** See below section for more information on Symptoms. */
  symptom?: InputMaybe<Scalars['SymptomName']['input']>;
  /** Whether or not to store the customers vehicle */
  willStore?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Override the initial JobStatus */
export const CreateJobStatusInput = {
  /** Draft */
  Draft: 'Draft'
} as const;

export type CreateJobStatusInput = typeof CreateJobStatusInput[keyof typeof CreateJobStatusInput];
/** Autogenerated input type of CreateJobTermination */
export type CreateJobTerminationInput = {
  autoAssignDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  category: JobTerminationCategoryEnumType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['ID']['input'];
  status: JobTerminationStatusEnumType;
  terminalStatusSubReason?: InputMaybe<TerminalStatusSubReasonEnumType>;
};

/** Autogenerated input type of CreateMobileIntakeEvent */
export type CreateMobileIntakeEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Step name for the mobile intake event. */
  stepName: Scalars['String']['input'];
};

/** Vehicle input for creating a mobile web job by clients */
export type CreateMobileIntakeJobByClientVehicle = {
  /** 'Vehicle Unit Number. Can be used for a Profile Search if search type is available for the client.' */
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle VIN. Can be used for a Profile Search if search type is available for the client. */
  vin?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateMobileIntakeJob */
export type CreateMobileIntakeJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  phone: Scalars['E164PhoneNumber']['input'];
};

/** Autogenerated input type of CreatePartnerVehicle */
export type CreatePartnerVehicleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  partnerVehicle: PartnerVehicleInput;
};

/** Autogenerated input type of CreatePasswordRequest */
export type CreatePasswordRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  passwordRequest: PasswordRequestInput;
};

/** Autogenerated input type of CreatePolicyLookup */
export type CreatePolicyLookupInput = {
  claimNumber?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fullVin?: InputMaybe<Scalars['String']['input']>;
  job: JobIdInput;
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreatePredraftJob */
export type CreatePredraftJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job?: InputMaybe<CreatePredraftJobJobInput>;
};

export type CreatePredraftJobJobInput = {
  account?: InputMaybe<AccountInput>;
  /** @deprecated This is no longer used and the value is ignored */
  autoAssignDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  caller?: InputMaybe<JobCallerInput>;
  /** Case details */
  case?: InputMaybe<CaseInputType>;
  /** Case SSID */
  caseId?: InputMaybe<Scalars['ID']['input']>;
  claim?: InputMaybe<JobClaimInput>;
  client?: InputMaybe<ClientInput>;
  clientProgram?: InputMaybe<ClientProgramInput>;
  customer?: InputMaybe<JobCustomerInput>;
  customerLookupType?: InputMaybe<Scalars['String']['input']>;
  customerPayment?: InputMaybe<JobCustomerPaymentInput>;
  /** Date of Loss */
  dateOfLoss?: InputMaybe<Scalars['Date']['input']>;
  dispatcherId?: InputMaybe<Scalars['ID']['input']>;
  eta?: InputMaybe<JobEtaInputType>;
  /** Exposure Number represents the number of times a vehicle has had a claim */
  exposureNumber?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<JobLocationInput>;
  notes?: InputMaybe<NotesInput>;
  partner?: InputMaybe<JobPartnerInput>;
  /** Deprecated: Please use paymentTypeName instead */
  paymentType?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  /** The name of the PaymentType used on the Job */
  paymentTypeName?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  priorityResponse?: InputMaybe<Scalars['Boolean']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  repairRefNumber?: InputMaybe<Scalars['String']['input']>;
  selectedPolicyDriver?: InputMaybe<SelectedPolicyDriverInput>;
  selectedPolicyVehicle?: InputMaybe<SelectedPolicyVehicleInput>;
  /** Service details */
  service?: InputMaybe<ServiceInput>;
  storageYard?: InputMaybe<StorageYardInput>;
  texts?: InputMaybe<JobTextsInput>;
  vehicle?: InputMaybe<VehicleInput>;
};

/** Autogenerated input type of CreatePreferredClient */
export type CreatePreferredClientInput = {
  /** The id of the client who is being given preferred status. */
  clientId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the rate agreement this client is preferred for. */
  rateAgreementId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreatePreferredClientSite */
export type CreatePreferredClientSiteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the preferred client this site is for. */
  rateAgreementPreferredClientId: Scalars['ID']['input'];
  /** The id of the site. */
  siteId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateProviderJobTerminationRequest */
export type CreateProviderJobTerminationRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Job to be terminated. */
  jobId: Scalars['ID']['input'];
  /** The ID of the reason the Job is being terminated. */
  providerJobTerminationReasonId: Scalars['ID']['input'];
  /** The type of termination that is being requested. */
  terminationType: JobTerminationStatusEnumType;
};

/** Autogenerated input type of CreateRateAgreement */
export type CreateRateAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rateAgreement: RateAgreementInput;
};

/** Autogenerated input type of CreateRate */
export type CreateRateInput = {
  changeReason: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rate: RateInput;
};

/** Autogenerated input type of CreateRecommendedDropoffLocationTypes */
export type CreateRecommendedDropoffLocationTypesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Company SSID */
  companyId: Scalars['ID']['input'];
  /** Location type Names */
  locationTypeNames: Array<Scalars['String']['input']>;
  /** Service Name */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** Symptom Name */
  symptomName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateReleaseInvoice */
export type CreateReleaseInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the release job to create the invoice for. */
  jobId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateSingleSignOnConnection */
export type CreateSingleSignOnConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  domain: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Autogenerated input type of CreateSite */
export type CreateSiteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  site: CreateSiteSiteInput;
};

export type CreateSiteSiteInput = {
  /** Applicable to PartnerCompany. If `true`, it means it is opened 24/7. */
  alwaysOpen?: InputMaybe<Scalars['Boolean']['input']>;
  /** TBD */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Applicable to PartnerCompany. If `true` this site will be available to receive dispatched Jobs. */
  dispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Site Email */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** Hours of operation */
  hoursOfOperation?: InputMaybe<HoursOfOperationInput>;
  /** Job status email preferences */
  jobStatusEmailPreferences?: InputMaybe<JobStatusEmailPreferencesInput>;
  /** The physical address of this Site. */
  location: LocationInput;
  /** Manager name. */
  managerName?: InputMaybe<Scalars['String']['input']>;
  /** Name of the Site. */
  name: Scalars['String']['input'];
  /** Night drop? */
  nightDrop?: InputMaybe<Scalars['Boolean']['input']>;
  /** Phone number of the Site. */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Referral Code */
  referralCode?: InputMaybe<Scalars['String']['input']>;
  /** Sponsor Rank */
  sponsorRank?: InputMaybe<Scalars['Int']['input']>;
  /** Type of the Site. */
  type?: InputMaybe<SiteEnumType>;
  /** Vehicle make restrictions for the site */
  vehicleMakes?: InputMaybe<Array<VehicleMakeInput>>;
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  /** SSID of user to be assigned to complete task. */
  assigneeId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Description of task to be completed. */
  description: Scalars['String']['input'];
  /** Date and time when task should be completed.  Expected in ISO8601 format. */
  dueAt: Scalars['DateTime']['input'];
  /** SSID for related job. */
  jobId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateTowDestinationApprovalRule */
export type CreateTowDestinationApprovalRuleInput = {
  /** Vehicle ClassType Name */
  classTypeName?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Company SSID */
  companyId: Scalars['ID']['input'];
  /** Client Program Code */
  programCode?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  user: UserInput;
};

/** Autogenerated input type of CreateVCCPayment */
export type CreateVCCPaymentInput = {
  /** SSID of the user approving the vcc payment. */
  approvingUserId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the job to create the vcc payment for. */
  jobId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateWave */
export type CreateWaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  maxBids: Scalars['Int']['input'];
  time: Scalars['Int']['input'];
};

export type CtiAgentChangeExtensionInput = {
  extension: Scalars['Int']['input'];
  oldExtension: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type CtiAgentLoginInput = {
  extension: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type CtiAgentStatusInput = {
  extension: Scalars['Int']['input'];
  status: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Currency */
export const Currency = {
  /** Euro */
  EUR: 'EUR',
  /** United States Dollar */
  USD: 'USD'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];
/** Customer input for creating a mobile web job by clients */
export type CustomerInput = {
  /** 'Customer claim number. Can be used for a Profile Search if search type is available for the client.' */
  claimNumber?: InputMaybe<Scalars['String']['input']>;
  /** 'Customer member number. Can be used for a Profile Search if search type is available for the client.' */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  /** Customer name. Can be used for a Profile Search if search type is available for the client. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Customer phone. Can be used for a Profile Search if search type is available for the client. */
  phone: Scalars['E164PhoneNumber']['input'];
  /** Customer policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /**
   * Customer postal code. Can be used for a Profile Search if search type is available for the client.
   * Postal codes are case insensitive and will ignore any suffix
   * after a hyphen after the first five characters (i.e. "53211" will match "53211-1234").
   */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Customer state abbreviation, e.g. CA or NY. Can be used for a Profile Search if search type is available for the client. */
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes to lookup a customer */
export type CustomerLookupInput = {
  /** Customer lookup search fields on customer */
  searchFields?: InputMaybe<Scalars['JSON']['input']>;
};

/** PLS customer lookup sources */
export const CustomerLookupSourceEnum = {
  /** Load Files */
  database: 'database',
  /** Direct Access */
  direct_access: 'direct_access'
} as const;

export type CustomerLookupSourceEnum = typeof CustomerLookupSourceEnum[keyof typeof CustomerLookupSourceEnum];
/** Customer lookup types */
export const CustomerLookupTypeEnum = {
  /** Claim Number */
  ClaimNumber: 'ClaimNumber',
  /** Company Name & Zip */
  CompanyNameZip: 'CompanyNameZip',
  /** Full Vin */
  FullVin: 'FullVin',
  /** Last 8 Vin */
  Last8Vin: 'Last8Vin',
  /** Last Name & Phone */
  LastNamePhone: 'LastNamePhone',
  /** Last Name & Zip */
  LastNameZip: 'LastNameZip',
  /** Member Number */
  MemberNumber: 'MemberNumber',
  /** Name, Address, Vehicle */
  NameAddressVehicle: 'NameAddressVehicle',
  /** Name, Phone, Vehicle */
  NamePhoneVehicle: 'NamePhoneVehicle',
  /** Name, State, and Zip */
  NameStateandZip: 'NameStateandZip',
  /** Phone Number */
  PhoneNumber: 'PhoneNumber',
  /** Policy Number */
  PolicyNumber: 'PolicyNumber',
  /** Service Contract Number */
  ServiceContractNumber: 'ServiceContractNumber',
  /** Skip and enter manually */
  Skipandentermanually: 'Skipandentermanually',
  /** Social Security Number */
  SocialSecurityNumber: 'SocialSecurityNumber',
  /** Unit Number */
  UnitNumber: 'UnitNumber'
} as const;

export type CustomerLookupTypeEnum = typeof CustomerLookupTypeEnum[keyof typeof CustomerLookupTypeEnum];
/** Dashboard Tab Type */
export const DashboardTabType = {
  /** Dashboard All Active Tabs */
  AllActive: 'AllActive',
  /** Dashboard Case Tab */
  Case: 'Case',
  /** Dashboard Done Tab */
  Done: 'Done',
  /** Dashboard Drafts Tab */
  Drafts: 'Drafts',
  /** Dashboard Escalations Tab */
  Escalations: 'Escalations',
  /** Dashboard In-Progress Tab */
  InProgress: 'InProgress',
  /** Dashboard Monitoring Tab */
  Monitoring: 'Monitoring',
  /** Dashboard Pending Tab */
  Pending: 'Pending',
  /** Reviews Default Tab */
  Reviews: 'Reviews',
  /** Dashboard Scheduled Tab */
  Scheduled: 'Scheduled'
} as const;

export type DashboardTabType = typeof DashboardTabType[keyof typeof DashboardTabType];
/** Date range input for filtering */
export type DateRangeInput = {
  /** From Date */
  from?: InputMaybe<Scalars['Date']['input']>;
  /** To Date */
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeRangeInput = {
  /** From DateTime */
  from?: InputMaybe<Scalars['DateTime']['input']>;
  /** To DateTime */
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Autogenerated input type of DeleteClientCompanyRequiredEquipmentRule */
export type DeleteClientCompanyRequiredEquipmentRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Required Equipment Rule SSID */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteClientProgram */
export type DeleteClientProgramInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteCompany */
export type DeleteCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteCoupon */
export type DeleteCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The external identifier of the coupon to delete */
  externalId: Scalars['String']['input'];
};

/** Autogenerated input type of DeleteCoverageNotes */
export type DeleteCoverageNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteDocumentDocumentInput = {
  id: Scalars['ID']['input'];
  invoiceAttachment?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of DeleteDocument */
export type DeleteDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  document: DeleteDocumentDocumentInput;
};

/** Autogenerated input type of DeleteEventLimit */
export type DeleteEventLimitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Event Limit SSID */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteInvoiceLineItem */
export type DeleteInvoiceLineItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The SSID of the invoice that the line item is being deleted from */
  invoiceId: Scalars['ID']['input'];
  /** The SSID of the line item that is being deleted */
  lineItemId: Scalars['ID']['input'];
  /** usage context of this mutation */
  usageContext?: InputMaybe<InvoiceUsageContextEnum>;
};

/** Autogenerated input type of DeleteInvoiceTransaction */
export type DeleteInvoiceTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the invoice that the transaction is being added to */
  invoiceId: Scalars['ID']['input'];
  /** The ID of the transaction that is being deleted */
  transactionId: Scalars['ID']['input'];
};

/** Autogenerated input type of DeletePartnerVehicle */
export type DeletePartnerVehicleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Id of Partner Vehicle */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteRateAgreement */
export type DeleteRateAgreementInput = {
  changeReason: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rateAgreementId: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteRate */
export type DeleteRateInput = {
  changeReason: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rateId: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteRates */
export type DeleteRatesInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rateTypeId?: InputMaybe<Scalars['ID']['input']>;
  serviceCodeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of DeleteRecommendedDropoffLocationTypes */
export type DeleteRecommendedDropoffLocationTypesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Company SSID */
  companyId: Scalars['ID']['input'];
  /** Service Name */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** Symptom Name */
  symptomName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DeleteSingleSignOnConnection */
export type DeleteSingleSignOnConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteSite */
export type DeleteSiteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  site: DeleteSiteSiteInput;
};

export type DeleteSiteSiteInput = {
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteSubscription */
export type DeleteSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscription: DeleteSubscriptionSubscriptionInput;
};

export type DeleteSubscriptionSubscriptionInput = {
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteTowDestinationApprovalRule */
export type DeleteTowDestinationApprovalRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Tow Destination Approval Rule SSID */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DeleteWave */
export type DeleteWaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyCompanyUser */
export type DestroyCompanyUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User ID */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DispatchJobOutOfNetwork */
export type DispatchJobOutOfNetworkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Job ID */
  id: Scalars['ID']['input'];
};

/** Dashboard column header keys. */
export const DisplayableColumns = {
  /** Account */
  Account: 'Account',
  /** Action */
  Action: 'Action',
  /** ATA */
  ActualToa: 'ActualToa',
  /** Alerts */
  Alerts: 'Alerts',
  /** Case ID */
  CaseId: 'CaseId',
  /** Color */
  Color: 'Color',
  /** Company */
  Company: 'Company',
  /** Completed Date */
  CompletedDate: 'CompletedDate',
  /** Created */
  CreatedTime: 'CreatedTime',
  /** Customer */
  CustomerName: 'CustomerName',
  /** Customer Phone */
  CustomerPhone: 'CustomerPhone',
  /** Payment Type */
  CustomerType: 'CustomerType',
  /** Department */
  Department: 'Department',
  /** Dispatcher */
  Dispatcher: 'Dispatcher',
  /** Driver */
  Driver: 'Driver',
  /** Drop Off */
  Drop: 'Drop',
  /** Equipment */
  Equipment: 'Equipment',
  /** ETA */
  EstimatedToa: 'EstimatedToa',
  /** ETA */
  Eta: 'Eta',
  /** ETA */
  Eta2: 'Eta2',
  /** Customer Comments */
  Feedback: 'Feedback',
  /** Site */
  FleetSite: 'FleetSite',
  /** Job ID */
  Id: 'Id',
  /** Class Type */
  InvoiceVehicleCategoryId: 'InvoiceVehicleCategoryId',
  /** L6 VIN */
  L6vin: 'L6vin',
  /** License */
  License: 'License',
  /** Location */
  Location: 'Location',
  /** Make */
  Make: 'Make',
  /** Model */
  Model: 'Model',
  /** Odometer */
  Odometer: 'Odometer',
  /** Partner */
  Partner: 'Partner',
  /** Department */
  PartnerDepartment: 'PartnerDepartment',
  /** Phone */
  Phone: 'Phone',
  /** PO */
  Po: 'Po',
  /** Policy Number */
  PolicyNumber: 'PolicyNumber',
  /** Customer Score */
  Q1: 'Q1',
  /** Q2 */
  Q2: 'Q2',
  /** Q3 */
  Q3: 'Q3',
  /** Reference */
  RefNumber: 'RefNumber',
  /** Serial */
  Serial: 'Serial',
  /** Service */
  Service: 'Service',
  /** Status */
  Status: 'Status',
  /** Status Age */
  StatusAge: 'StatusAge',
  /** Style */
  Style: 'Style',
  /** Total Time */
  TotalTime: 'TotalTime',
  /** Truck */
  Truck: 'Truck',
  /** Unit Number */
  UnitNumber: 'UnitNumber',
  /** Wait Time */
  WaitTime: 'WaitTime',
  /** Year */
  Year: 'Year'
} as const;

export type DisplayableColumns = typeof DisplayableColumns[keyof typeof DisplayableColumns];
/** Distance Unit */
export const DistanceUnit = {
  /** Kilometer */
  km: 'km',
  /** Mile */
  mi: 'mi'
} as const;

export type DistanceUnit = typeof DistanceUnit[keyof typeof DistanceUnit];
/** Document Container */
export const DocumentContainerType = {
  /** Dropoff */
  Dropoff: 'Dropoff',
  /** Pickup */
  Pickup: 'Pickup',
  /** Release */
  Release: 'Release'
} as const;

export type DocumentContainerType = typeof DocumentContainerType[keyof typeof DocumentContainerType];
export type DocumentInput = {
  container?: InputMaybe<DocumentContainerType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  document: Scalars['JSON']['input'];
  invoiceAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  job: DocumentJobInput;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  /** @deprecated Use lat and lng instead */
  location?: InputMaybe<LocationInput>;
  type: DocumentTypeType;
};

export type DocumentJobInput = {
  id: Scalars['ID']['input'];
};

/** Document Type */
export const DocumentTypeType = {
  /** BulkAgentDeleteFile */
  BulkAgentDeleteFile: 'BulkAgentDeleteFile',
  /** BulkAgentImportFile */
  BulkAgentImportFile: 'BulkAgentImportFile',
  /** BulkClientUserDeleteFile */
  BulkClientUserDeleteFile: 'BulkClientUserDeleteFile',
  /** BulkClientUserImportFile */
  BulkClientUserImportFile: 'BulkClientUserImportFile',
  /** Document */
  Document: 'Document',
  /** LocationImage */
  LocationImage: 'LocationImage',
  /** SignatureImage */
  SignatureImage: 'SignatureImage'
} as const;

export type DocumentTypeType = typeof DocumentTypeType[keyof typeof DocumentTypeType];
/** Autogenerated input type of DownloadInvoice */
export type DownloadInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being downloaded. */
  id: Scalars['ID']['input'];
};

/** Allowed values for type of dropdown data to fetch */
export const DropdownDataEnum = {
  ClientCompanies: 'ClientCompanies',
  Companies: 'Companies',
  Dispatchers: 'Dispatchers',
  Drivers: 'Drivers',
  HeavyDutyVehicleMakes: 'HeavyDutyVehicleMakes',
  LightDutyVehicleMakes: 'LightDutyVehicleMakes',
  RescueVehicles: 'RescueVehicles',
  ServiceProviderCompanies: 'ServiceProviderCompanies',
  Users: 'Users',
  VehicleMakes: 'VehicleMakes',
  VehicleModelsForMake: 'VehicleModelsForMake'
} as const;

export type DropdownDataEnum = typeof DropdownDataEnum[keyof typeof DropdownDataEnum];
/** Filter criteria for dropdown data */
export type DropdownDataFilter = {
  /** The key used for filtering dropdown data */
  key: DropdownDataFilterEnum;
  /** The JSON value associated with the filter key */
  value: Scalars['JSON']['input'];
};

/** This is an enum type for dropdown data filters */
export const DropdownDataFilterEnum = {
  VehicleMakeId: 'VehicleMakeId',
  VehicleMakeName: 'VehicleMakeName',
  VehicleMakeSsId: 'VehicleMakeSsId'
} as const;

export type DropdownDataFilterEnum = typeof DropdownDataFilterEnum[keyof typeof DropdownDataFilterEnum];
export type EditJobStatusBiddedEta = {
  /** Bidded ETA for JobStatus Accepted only */
  bidded?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Autogenerated input type of EditJobStatus */
export type EditJobStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dashboardValidations?: InputMaybe<UpdateJobStatusDashboardValidations>;
  driverValidations?: InputMaybe<UpdateJobStatusDriverValidations>;
  job?: InputMaybe<EditJobStatusJobInput>;
};

export type EditJobStatusJobInput = {
  eta?: InputMaybe<EditJobStatusBiddedEta>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<EditJobStatusNotes>;
  /** Create an record of the on-site time */
  onSiteAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reason, in case required by the Status this Job is changed into */
  reason?: InputMaybe<Scalars['String']['input']>;
  referredProviderId?: InputMaybe<Scalars['ID']['input']>;
  referredRateAgreementId?: InputMaybe<Scalars['ID']['input']>;
  /** Update rescue vehicle location */
  rescueVehicleLocation?: InputMaybe<LatitudeLongitudeInput>;
  /** Job Status. */
  status: JobStatus;
};

export type EditJobStatusNotes = {
  /** Additional notes to be used for Rejected status */
  rejected?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of EmailInvoice */
export type EmailInvoiceInput = {
  /** List of attached document IDs to set as invoice attachments */
  attachedDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** List of emails to bcc the invoice to */
  bcc?: InputMaybe<Scalars['EmailsCommaSeparated']['input']>;
  /** List of emails to cc the invoice to */
  cc?: InputMaybe<Scalars['EmailsCommaSeparated']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being emailed. */
  id: Scalars['ID']['input'];
  /** Primary recipieint email */
  to: Scalars['EmailsCommaSeparated']['input'];
};

/** Strings to display in Dashboard ETA column */
export const EtaString = {
  /** Auction in progress */
  Collecting: 'Collecting',
  /** Auction failed */
  Failed: 'Failed'
} as const;

export type EtaString = typeof EtaString[keyof typeof EtaString];
/** Event Limit Target */
export const EventLimitTarget = {
  /** Policy */
  Policy: 'Policy',
  /** Vehicle */
  Vehicle: 'Vehicle'
} as const;

export type EventLimitTarget = typeof EventLimitTarget[keyof typeof EventLimitTarget];
/** Feature Enum Type */
export const FeatureEnum = {
  /** Accident Management */
  AccidentManagement: 'AccidentManagement',
  /** Accident Tow Transfer */
  AccidentTowTransfer: 'AccidentTowTransfer',
  /** Add Driver Name on Invoice */
  AddDriverNameonInvoice: 'AddDriverNameonInvoice',
  /** Add Partner Column to Fleet Managed */
  AddPartnerColumntoFleetManaged: 'AddPartnerColumntoFleetManaged',
  /** Alert Complete Confirmation */
  AlertCompleteConfirmation: 'AlertCompleteConfirmation',
  /** Alert Delayed Dispatch */
  AlertDelayedDispatch: 'AlertDelayedDispatch',
  /** Alert ETA Expiry */
  AlertETAExpiry: 'AlertETAExpiry',
  /** Alert_Incomplete Drafts */
  AlertIncompleteDrafts: 'AlertIncompleteDrafts',
  /** Alert Manual Dispatch */
  AlertManualDispatch: 'AlertManualDispatch',
  /** Alert_Proactive ETA Threshold */
  AlertProactiveETAThreshold: 'AlertProactiveETAThreshold',
  /** Alert Release Required */
  AlertReleaseRequired: 'AlertReleaseRequired',
  /** Alert Screen Pops */
  AlertScreenPops: 'AlertScreenPops',
  /** Alert Service Recovery */
  AlertServiceRecovery: 'AlertServiceRecovery',
  /** Allow Invoice Add Item */
  AllowInvoiceAddItem: 'AllowInvoiceAddItem',
  /** Allow OON Dispatch for Not-Covered Jobs */
  AllowOONDispatchforNotCoveredJobs: 'AllowOONDispatchforNotCoveredJobs',
  /** Alternate Ride Request */
  AlternateRideRequest: 'AlternateRideRequest',
  /** Always Show Caller */
  AlwaysShowCaller: 'AlwaysShowCaller',
  /** Auto Assign */
  AutoAssign: 'AutoAssign',
  /** Auto Assign Driver */
  AutoAssignDriver: 'AutoAssignDriver',
  /** Auto Assign PA Mode */
  AutoAssignPAMode: 'AutoAssignPAMode',
  /** Auto Complete */
  AutoComplete: 'AutoComplete',
  /** Auto On-Site */
  AutoOnSite: 'AutoOnSite',
  /** Auto Select Site from Lookup */
  AutoSelectSitefromLookup: 'AutoSelectSitefromLookup',
  /** Canadian AP */
  CanadianAP: 'CanadianAP',
  /** Class Type Required */
  ClassTypeRequired: 'ClassTypeRequired',
  /** Client API Coverage Override */
  ClientAPICoverageOverride: 'ClientAPICoverageOverride',
  /** Client API Coverage Required */
  ClientAPICoverageRequired: 'ClientAPICoverageRequired',
  /** Client Program Auto Select */
  ClientProgramAutoSelect: 'ClientProgramAutoSelect',
  /** Client Sites */
  ClientSites: 'ClientSites',
  /** Compliance: Auto Assign */
  ComplianceAutoAssign: 'ComplianceAutoAssign',
  /** Compliance: Manual Assign */
  ComplianceManualAssign: 'ComplianceManualAssign',
  /** Credit Card Collection */
  CreditCardCollection: 'CreditCardCollection',
  /** Custom Statement Descriptor */
  CustomStatementDescriptor: 'CustomStatementDescriptor',
  /** Customer Payment Collection */
  CustomerPaymentCollection: 'CustomerPaymentCollection',
  /** Customer Pays */
  CustomerPays: 'CustomerPays',
  /** Customer Rates Integration */
  CustomerRatesIntegration: 'CustomerRatesIntegration',
  /** Customer View Co-Branding */
  CustomerViewCoBranding: 'CustomerViewCoBranding',
  /** Department Filter */
  DepartmentFilter: 'DepartmentFilter',
  /** Departments */
  Departments: 'Departments',
  /** Disable App Create Jobs */
  DisableAppCreateJobs: 'DisableAppCreateJobs',
  /** Disable App Drivers Create Jobs */
  DisableAppDriversCreateJobs: 'DisableAppDriversCreateJobs',
  /** Disable App Drivers Edit Jobs */
  DisableAppDriversEditJobs: 'DisableAppDriversEditJobs',
  /** Disable App Edit Jobs */
  DisableAppEditJobs: 'DisableAppEditJobs',
  /** Disable Bank Settings */
  DisableBankSettings: 'DisableBankSettings',
  /** Disable Delete Job */
  DisableDeleteJob: 'DisableDeleteJob',
  /** Disable Force Photos */
  DisableForcePhotos: 'DisableForcePhotos',
  /** Disable License Restrictions */
  DisableLicenseRestrictions: 'DisableLicenseRestrictions',
  /** Disable Mobile VIN Lookup */
  DisableMobileVINLookup: 'DisableMobileVINLookup',
  /** Disable Referral Tow Destination Recommendations */
  DisableReferralTowDestinationRecommendations: 'DisableReferralTowDestinationRecommendations',
  /** Disable Self-Service Payments */
  DisableSelfServicePayments: 'DisableSelfServicePayments',
  /** Disable driver edit invoice */
  Disabledrivereditinvoice: 'Disabledrivereditinvoice',
  /** Disable driver view invoice */
  Disabledriverviewinvoice: 'Disabledriverviewinvoice',
  /** Disable force service resolution */
  Disableforceserviceresolution: 'Disableforceserviceresolution',
  /** Disable user email notifications */
  Disableuseremailnotifications: 'Disableuseremailnotifications',
  /** Dispatch Robocalls */
  DispatchRobocalls: 'DispatchRobocalls',
  /** Don't require customer name and phone */
  Dontrequirecustomernameandphone: 'Dontrequirecustomernameandphone',
  /** ETA Range */
  ETARange: 'ETARange',
  /** Exclusive Partner */
  ExclusivePartner: 'ExclusivePartner',
  /** Flat Rate Tow */
  FlatRateTow: 'FlatRateTow',
  /** Fleet Service Map */
  FleetServiceMap: 'FleetServiceMap',
  /** Hide 4WD */
  Hide4WD: 'Hide4WD',
  /** Hide Location Contacts */
  HideLocationContacts: 'HideLocationContacts',
  /** Hide Partner ETA */
  HidePartnerETA: 'HidePartnerETA',
  /** Hide Reporting */
  HideReporting: 'HideReporting',
  /** International Phone Numbers */
  InternationalPhoneNumbers: 'InternationalPhoneNumbers',
  /** Invoice Custom Items */
  InvoiceCustomItems: 'InvoiceCustomItems',
  /** Invoice Warning */
  InvoiceWarning: 'InvoiceWarning',
  /** Invoices */
  Invoices: 'Invoices',
  /** Invoicing */
  Invoicing: 'Invoicing',
  /** Issues */
  Issues: 'Issues',
  /** Job Form Authorize Request hidden for Pre-Draft */
  JobFormAuthorizeRequesthiddenforPreDraft: 'JobFormAuthorizeRequesthiddenforPreDraft',
  /** Job Form Claim Number */
  JobFormClaimNumber: 'JobFormClaimNumber',
  /** Job Form Client Authorization for Partially Covered */
  JobFormClientAuthorizationforPartiallyCovered: 'JobFormClientAuthorizationforPartiallyCovered',
  /** Job Form Client Authorizations */
  JobFormClientAuthorizations: 'JobFormClientAuthorizations',
  /** Job Form Client Authorizations for RV and Motorcycle */
  JobFormClientAuthorizationsforRVandMotorcycle: 'JobFormClientAuthorizationsforRVandMotorcycle',
  /** Job Form Copy Profile Phone Number */
  JobFormCopyProfilePhoneNumber: 'JobFormCopyProfilePhoneNumber',
  /** Job Form Coverage */
  JobFormCoverage: 'JobFormCoverage',
  /** Job Form Customer Lookup */
  JobFormCustomerLookup: 'JobFormCustomerLookup',
  /** Job Form Downtime Program Handling */
  JobFormDowntimeProgramHandling: 'JobFormDowntimeProgramHandling',
  /** Job Form Email Field */
  JobFormEmailField: 'JobFormEmailField',
  /** Job Form Email Field Required */
  JobFormEmailFieldRequired: 'JobFormEmailFieldRequired',
  /** Job Form Enable Client Company User Client Authorization */
  JobFormEnableClientCompanyUserClientAuthorization: 'JobFormEnableClientCompanyUserClientAuthorization',
  /** Job Form License Plate Field Required */
  JobFormLicensePlateFieldRequired: 'JobFormLicensePlateFieldRequired',
  /** Job Form Odometer Required */
  JobFormOdometerRequired: 'JobFormOdometerRequired',
  /** Job Form PO Number Required */
  JobFormPONumberRequired: 'JobFormPONumberRequired',
  /** Job Form Policy Number */
  JobFormPolicyNumber: 'JobFormPolicyNumber',
  /** Job Form Policy Number Partial Search */
  JobFormPolicyNumberPartialSearch: 'JobFormPolicyNumberPartialSearch',
  /** Job Form Policy Number Required */
  JobFormPolicyNumberRequired: 'JobFormPolicyNumberRequired',
  /** Job Form Policy # Required for Client Authorizations */
  JobFormPolicyRequiredforClientAuthorizations: 'JobFormPolicyRequiredforClientAuthorizations',
  /** Job Form Program Selection */
  JobFormProgramSelection: 'JobFormProgramSelection',
  /** Job Form Recommended Drop Off */
  JobFormRecommendedDropOff: 'JobFormRecommendedDropOff',
  /** Job Form Restricted Supervisor Authorization Reasons */
  JobFormRestrictedSupervisorAuthorizationReasons: 'JobFormRestrictedSupervisorAuthorizationReasons',
  /** Job Form Service Contract # Required for Authorizations */
  JobFormServiceContractRequiredforAuthorizations: 'JobFormServiceContractRequiredforAuthorizations',
  /** Job Form Site Required */
  JobFormSiteRequired: 'JobFormSiteRequired',
  /** Job Form Supervisor Authorizations */
  JobFormSupervisorAuthorizations: 'JobFormSupervisorAuthorizations',
  /** Job Form Tow Destination Approval */
  JobFormTowDestinationApproval: 'JobFormTowDestinationApproval',
  /** Job Form Tow Destination Priority */
  JobFormTowDestinationPriority: 'JobFormTowDestinationPriority',
  /** Job Form Unit Required */
  JobFormUnitRequired: 'JobFormUnitRequired',
  /** Job Form Valid VIN Required for Client Authorizations */
  JobFormValidVINRequiredforClientAuthorizations: 'JobFormValidVINRequiredforClientAuthorizations',
  /** Job Form Valid VIN Required for Supervisor Authorizations */
  JobFormValidVINRequiredforSupervisorAuthorizations: 'JobFormValidVINRequiredforSupervisorAuthorizations',
  /** Job Member Number */
  JobMemberNumber: 'JobMemberNumber',
  /** Job Member Number Required */
  JobMemberNumberRequired: 'JobMemberNumberRequired',
  /** Job PO Number */
  JobPONumber: 'JobPONumber',
  /** Job Priority Response Profile Auto Select */
  JobPriorityResponseProfileAutoSelect: 'JobPriorityResponseProfileAutoSelect',
  /** Job Reference Number */
  JobReferenceNumber: 'JobReferenceNumber',
  /** Job Unit Number */
  JobUnitNumber: 'JobUnitNumber',
  /** Location Contacts */
  LocationContacts: 'LocationContacts',
  /** Looker */
  Looker: 'Looker',
  /** MC Job AutoReject Override */
  MCJobAutoRejectOverride: 'MCJobAutoRejectOverride',
  /** Manual Assign: Accident Tow (P) Fallback */
  ManualAssignAccidentTowPFallback: 'ManualAssignAccidentTowPFallback',
  /** Manual Assign: Dispatchable Partners Only */
  ManualAssignDispatchablePartnersOnly: 'ManualAssignDispatchablePartnersOnly',
  /** Manual Assign: OON Dispatch */
  ManualAssignOONDispatch: 'ManualAssignOONDispatch',
  /** Mobile Web Intake */
  MobileWebIntake: 'MobileWebIntake',
  /** Mobile Web Intake Standard Symptoms */
  MobileWebIntakeStandardSymptoms: 'MobileWebIntakeStandardSymptoms',
  /** Multi-Factor Authentication */
  MultiFactorAuthentication: 'MultiFactorAuthentication',
  /** NPS External Survey Redirect Enabled */
  NPSExternalSurveyRedirectEnabled: 'NPSExternalSurveyRedirectEnabled',
  /** Odometer */
  Odometer: 'Odometer',
  /** Optional Credit Card Zip */
  OptionalCreditCardZip: 'OptionalCreditCardZip',
  /** PO On Dashboard */
  POOnDashboard: 'POOnDashboard',
  /** Partner Dispatch to Truck */
  PartnerDispatchtoTruck: 'PartnerDispatchtoTruck',
  /** Payment Type */
  PaymentType: 'PaymentType',
  /** Plane Animation */
  PlaneAnimation: 'PlaneAnimation',
  /** Post-NPS Winback */
  PostNPSWinback: 'PostNPSWinback',
  /** Pre-NPS Winback */
  PreNPSWinback: 'PreNPSWinback',
  /** Prevent Job Dispatch */
  PreventJobDispatch: 'PreventJobDispatch',
  /** Priority Response */
  PriorityResponse: 'PriorityResponse',
  /** Provider Invoices: Restrict Add Ons */
  ProviderInvoicesRestrictAddOns: 'ProviderInvoicesRestrictAddOns',
  /** Provider Referral */
  ProviderReferral: 'ProviderReferral',
  /** Questions */
  Questions: 'Questions',
  /** Referred to Canceled Client API Mask */
  ReferredtoCanceledClientAPIMask: 'ReferredtoCanceledClientAPIMask',
  /** Remove invoice timestamps per account */
  Removeinvoicetimestampsperaccount: 'Removeinvoicetimestampsperaccount',
  /** Repair Advantage Tow Coupons */
  RepairAdvantageTowCoupons: 'RepairAdvantageTowCoupons',
  /** Review 4 Texts */
  Review4Texts: 'Review4Texts',
  /** Review Choose */
  ReviewChoose: 'ReviewChoose',
  /** Review Feed */
  ReviewFeed: 'ReviewFeed',
  /** Review NPS Mobile Web */
  ReviewNPSMobileWeb: 'ReviewNPSMobileWeb',
  /** Review NPS Q1 SMS */
  ReviewNPSQ1SMS: 'ReviewNPSQ1SMS',
  /** Review Pure NPS */
  ReviewPureNPS: 'ReviewPureNPS',
  /** Reviews Feed Q1 Filter */
  ReviewsFeedQ1Filter: 'ReviewsFeedQ1Filter',
  /** SMS Confirm Complete */
  SMSConfirmComplete: 'SMSConfirmComplete',
  /** SMS Confirm On Site */
  SMSConfirmOnSite: 'SMSConfirmOnSite',
  /** SMS Draft Follow Up */
  SMSDraftFollowUp: 'SMSDraftFollowUp',
  /** SMS_Relative ETA */
  SMSRelativeETA: 'SMSRelativeETA',
  /** SMS_Request Location */
  SMSRequestLocation: 'SMSRequestLocation',
  /** SMS_Reviews */
  SMSReviews: 'SMSReviews',
  /** SMS_Tire Change Safety */
  SMSTireChangeSafety: 'SMSTireChangeSafety',
  /** SMS Updates */
  SMSUpdates: 'SMSUpdates',
  /** SMS Verify Status */
  SMSVerifyStatus: 'SMSVerifyStatus',
  /** SSO Fallback */
  SSOFallback: 'SSOFallback',
  /** Serial Number */
  SerialNumber: 'SerialNumber',
  /** Settings */
  Settings: 'Settings',
  /** Shareable Sites */
  ShareableSites: 'ShareableSites',
  /** Show Client Sites to Swoop */
  ShowClientSitestoSwoop: 'ShowClientSitestoSwoop',
  /** Show Customer Payment Info */
  ShowCustomerPaymentInfo: 'ShowCustomerPaymentInfo',
  /** Show ETA on mobile job details */
  ShowETAonmobilejobdetails: 'ShowETAonmobilejobdetails',
  /** Show Symptoms */
  ShowSymptoms: 'ShowSymptoms',
  /** Show Vehicle Sub Type Field */
  ShowVehicleSubTypeField: 'ShowVehicleSubTypeField',
  /** Single Sign On */
  SingleSignOn: 'SingleSignOn',
  /** Singular Referral Tow Destination */
  SingularReferralTowDestination: 'SingularReferralTowDestination',
  /** Storage */
  Storage: 'Storage',
  /** Storage Management */
  StorageManagement: 'StorageManagement',
  /** Swoop Root All Vehicle Updates */
  SwoopRootAllVehicleUpdates: 'SwoopRootAllVehicleUpdates',
  /** Task Management */
  TaskManagement: 'TaskManagement',
  /** Telephony Integration */
  TelephonyIntegration: 'TelephonyIntegration',
  /** Towbook OON */
  TowbookOON: 'TowbookOON',
  /** Trailers */
  Trailers: 'Trailers',
  /** Uber */
  Uber: 'Uber',
  /** Unlisted Trailer Question */
  UnlistedTrailerQuestion: 'UnlistedTrailerQuestion',
  /** Unlisted Vehicle Option */
  UnlistedVehicleOption: 'UnlistedVehicleOption',
  /** Unlisted Vehicle Owner & Passenger Question */
  UnlistedVehicleOwnerPassengerQuestion: 'UnlistedVehicleOwnerPassengerQuestion',
  /** Unpaid to Draft Client API Mask */
  UnpaidtoDraftClientAPIMask: 'UnpaidtoDraftClientAPIMask',
  /** VIP */
  VIP: 'VIP',
  /** Vehicle Condition Questions */
  VehicleConditionQuestions: 'VehicleConditionQuestions',
  /** View Only Client */
  ViewOnlyClient: 'ViewOnlyClient'
} as const;

export type FeatureEnum = typeof FeatureEnum[keyof typeof FeatureEnum];
export type FleetRescueProviderInput = {
  accountingEmail: Scalars['String']['input'];
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  dispatchEmail: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  locationCode?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  primaryContact?: InputMaybe<Scalars['String']['input']>;
  primaryEmail?: InputMaybe<Scalars['String']['input']>;
  primaryPhone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  vendorCode?: InputMaybe<Scalars['String']['input']>;
};

/** Fleet site for a job. */
export type FleetSiteInput = {
  /** ID of the fleet site */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of ForceVersionUpdate */
export type ForceVersionUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** If the update should be urgent */
  urgent: Scalars['Boolean']['input'];
};

/** Represents the Full VIN Policy lookup. */
export type FullVINInput = {
  /** Full VIN */
  fullVin: Scalars['String']['input'];
};

export type GovernmentIdInput = {
  /** Government id */
  governmentId: Scalars['String']['input'];
};

export type HoursOfOperationInput = {
  /** Open/Close times for Friday */
  friday?: InputMaybe<OpenCloseTimeInput>;
  /** Open/Close times for Monday */
  monday?: InputMaybe<OpenCloseTimeInput>;
  /** Open/Close times for Saturday */
  saturday?: InputMaybe<OpenCloseTimeInput>;
  /** Open/Close times for Sunday */
  sunday?: InputMaybe<OpenCloseTimeInput>;
  /** Open/Close times for Thursday */
  thursday?: InputMaybe<OpenCloseTimeInput>;
  /** Open/Close times for Tuesday */
  tuesday?: InputMaybe<OpenCloseTimeInput>;
  /** Open/Close times for Wednesday */
  wednesday?: InputMaybe<OpenCloseTimeInput>;
};

export type Input = {
  /** Vehicle ClassType Name */
  classType?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  id: Scalars['ID']['input'];
  /** Deprecated, please use partnerVehicle.location.lat instead */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** Deprecated, please use partnerVehicle.location.lng instead */
  lng?: InputMaybe<Scalars['Float']['input']>;
  location?: InputMaybe<VehicleLocationInput>;
  /** Vehicle Make Name */
  make?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Model Name */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Year */
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Insights reports enumerated */
export const InsightsReportEnum = {
  /** Account Receivables */
  AccountReceivables: 'AccountReceivables',
  /** Agero Plus Portal */
  AgeroPlusPortal: 'AgeroPlusPortal',
  /** Alternative Transportation */
  AlternativeTransportation: 'AlternativeTransportation',
  /** Audi Assist Dashboard */
  AudiAssistDashboard: 'AudiAssistDashboard',
  /** Audi Dispatch Report */
  AudiDispatchReport: 'AudiDispatchReport',
  /** Command Center Dashboard */
  CommandCenterDashboard: 'CommandCenterDashboard',
  /** Company Overview */
  CompanyOverview: 'CompanyOverview',
  /** Company Overview Dashboard */
  CompanyOverviewDashboard: 'CompanyOverviewDashboard',
  /** Company Summary */
  CompanySummary: 'CompanySummary',
  /** Company Summary 1 (Nicks Towing) */
  CompanySummary1NicksTowing: 'CompanySummary1NicksTowing',
  /** Company Summary 2 (Nicks Towing) */
  CompanySummary2NicksTowing: 'CompanySummary2NicksTowing',
  /** Complaints and Damages Report */
  ComplaintsandDamagesReport: 'ComplaintsandDamagesReport',
  /** Customer Experience Overview */
  CustomerExperienceOverview: 'CustomerExperienceOverview',
  /** Customer Survey Feed */
  CustomerSurveyFeed: 'CustomerSurveyFeed',
  /** Dispatch Activity */
  DispatchActivity: 'DispatchActivity',
  /** Fleet Program Overview */
  FleetProgramOverview: 'FleetProgramOverview',
  /** Incentivization Report */
  IncentivizationReport: 'IncentivizationReport',
  /** MBUSA - Dealer Tech Scorecards */
  MBUSADealerTechScorecards: 'MBUSADealerTechScorecards',
  /** Metrics by State w ATA */
  MetricsbyStatewATA: 'MetricsbyStatewATA',
  /** Metrics by State w RCT */
  MetricsbyStatewRCT: 'MetricsbyStatewRCT',
  /** Metrics by State w TCET */
  MetricsbyStatewTCET: 'MetricsbyStatewTCET',
  /** Model Breakdown */
  ModelBreakdown: 'ModelBreakdown',
  /** Monthly Trends */
  MonthlyTrends: 'MonthlyTrends',
  /** OEM Performance Insights Dashboard w ATA */
  OEMPerformanceInsightsDashboardwATA: 'OEMPerformanceInsightsDashboardwATA',
  /** OEM Performance Insights Dashboard w RCT */
  OEMPerformanceInsightsDashboardwRCT: 'OEMPerformanceInsightsDashboardwRCT',
  /** OEM Performance Insights Dashboard w TCET */
  OEMPerformanceInsightsDashboardwTCET: 'OEMPerformanceInsightsDashboardwTCET',
  /** Performance At A Glance (GetAround Specific) */
  PerformanceAtAGlanceGetAroundSpecific: 'PerformanceAtAGlanceGetAroundSpecific',
  /** Performance At a Glance */
  PerformanceAtaGlance: 'PerformanceAtaGlance',
  /** Performance Insights Dashboard Fleet */
  PerformanceInsightsDashboardFleet: 'PerformanceInsightsDashboardFleet',
  /** Performance Insights Dashboard Summary w TCET */
  PerformanceInsightsDashboardSummarywTCET: 'PerformanceInsightsDashboardSummarywTCET',
  /** Performance Insights Dashboard w ATA */
  PerformanceInsightsDashboardwATA: 'PerformanceInsightsDashboardwATA',
  /** Performance Insights Dashboard w RCT */
  PerformanceInsightsDashboardwRCT: 'PerformanceInsightsDashboardwRCT',
  /** Performance Insights Dashboard w TCET */
  PerformanceInsightsDashboardwTCET: 'PerformanceInsightsDashboardwTCET',
  /** Porsche Dealership Linked Providers Report */
  PorscheDealershipLinkedProvidersReport: 'PorscheDealershipLinkedProvidersReport',
  /** Program Overview */
  ProgramOverview: 'ProgramOverview',
  /** Reimbursement Report */
  ReimbursementReport: 'ReimbursementReport',
  /** Revenue */
  Revenue: 'Revenue',
  /** SP Insights Dashboard */
  SPInsightsDashboard: 'SPInsightsDashboard',
  /** SP Insights Dashboard (including Agero Plus) */
  SPInsightsDashboardincludingAgeroPlus: 'SPInsightsDashboardincludingAgeroPlus',
  /** Service Abuser Report */
  ServiceAbuserReport: 'ServiceAbuserReport',
  /** Service Provider Health Check */
  ServiceProviderHealthCheck: 'ServiceProviderHealthCheck',
  /** Service Provider Payments */
  ServiceProviderPayments: 'ServiceProviderPayments',
  /** Service Provider Performance */
  ServiceProviderPerformance: 'ServiceProviderPerformance',
  /** Swoop Job Updates */
  SwoopJobUpdates: 'SwoopJobUpdates',
  /** USAA - Adjuster Reporting */
  USAAAdjusterReporting: 'USAAAdjusterReporting',
  /** VW Dispatch Report */
  VWDispatchReport: 'VWDispatchReport',
  /** VW Mobile Service Dashboard */
  VWMobileServiceDashboard: 'VWMobileServiceDashboard',
  /** Weekly Trends */
  WeeklyTrends: 'WeeklyTrends',
  /** Winback Dashboard */
  WinbackDashboard: 'WinbackDashboard'
} as const;

export type InsightsReportEnum = typeof InsightsReportEnum[keyof typeof InsightsReportEnum];
/** Input type for updating invoice attributes */
export type InvoiceAttributesInput = {
  /** The billing type of the invoice */
  billingType?: InputMaybe<BillingType>;
  /** The SSID of the invoice being updated */
  id: Scalars['ID']['input'];
};

/** Filters for Invoices */
export type InvoiceFiltersInput = {
  /** The ID of the Invoice Account */
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** Date range for Invoice creation */
  createdAt?: InputMaybe<DateRangeInput>;
  /** The ID of the Invoice Provider */
  providerId?: InputMaybe<Scalars['ID']['input']>;
  /** Search query for invoices */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Invoice Status Group */
  statusGroup?: InputMaybe<InvoiceStatusGroupEnum>;
};

export type InvoiceInput = {
  balance: Scalars['String']['input'];
  classType?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  classTypeName?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  id: Scalars['ID']['input'];
  job: InvoiceJobInput;
  lineItems: Array<InvoiceLineItemInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  payments: Array<InvoicePaymentOrCreditInput>;
  previousClassType?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  previousRateType?: InputMaybe<InvoiceRateTypeInput>;
  rateType: InvoiceRateTypeInput;
  subtotal: Scalars['String']['input'];
  totalAmount: Scalars['String']['input'];
};

export type InvoiceJobInput = {
  id: Scalars['ID']['input'];
};

/** Set the line item calc type. It can be flat or percentage. */
export const InvoiceLineItemCalcTypeEnum = {
  /** flat */
  Flat: 'Flat',
  /** percent */
  Percent: 'Percent'
} as const;

export type InvoiceLineItemCalcTypeEnum = typeof InvoiceLineItemCalcTypeEnum[keyof typeof InvoiceLineItemCalcTypeEnum];
export type InvoiceLineItemInput = {
  /** Used to flag whether Storage item quantity is auto_calculated (true) or changed by the user (false) */
  autoCalculatedQuantity: Scalars['Boolean']['input'];
  /** The Calc Type to be used for this LineItem. Can be percentage or flat. */
  calcType: InvoiceLineItemCalcTypeEnum;
  /** Date and time when this LineItem was deleted. */
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** LineItem description. */
  description: Scalars['String']['input'];
  /**
   * Flags it as estimated by the system. If `false`, it means the PartnerCompany
   * Admin has changed its original amount or quantity.
   */
  estimated: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  job?: InputMaybe<LineItemJobInput>;
  /** Net amount of this LineItem. It will likely be quantity multiplied by unit_price. */
  netAmount: Scalars['String']['input'];
  /** Original quantity of this LineItem. */
  originalQuantity: Scalars['String']['input'];
  /** Original Unit Price inherited by the Rate from where this LineItem was based on. */
  originalUnitPrice: Scalars['String']['input'];
  /** Quantity of this LineItem. */
  quantity: Scalars['String']['input'];
  /** Rate from where this LineItem auto-calculated value was based on. */
  rate?: InputMaybe<InvoiceRateInput>;
  /** Tax amount of this LineItem. */
  taxAmount: Scalars['String']['input'];
  /** Unit Price inherited by the Rate from where this LineItem was based on. */
  unitPrice: Scalars['String']['input'];
  /** Flags as user created. It means it was not auto-calculated by the system, but added manually by the PartnerCompany Admin. */
  userCreated: Scalars['Boolean']['input'];
};

/** Severity for Invoice Line Item validation message */
export const InvoiceLineItemValidationSeverityEnum = {
  /** INFO */
  INFO: 'INFO'
} as const;

export type InvoiceLineItemValidationSeverityEnum = typeof InvoiceLineItemValidationSeverityEnum[keyof typeof InvoiceLineItemValidationSeverityEnum];
/** Filters for Invoice Line Items */
export type InvoiceLineItemsFilters = {
  /** option to filter by line item description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** option to exclude tax line items */
  excludeTax?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Option for invoice dashboard */
export const InvoiceOptionsEnum = {
  /** Download Invoice */
  Download: 'Download',
  /** Email Invoice */
  Email: 'Email',
  /** Mark Paid */
  MarkPaid: 'MarkPaid',
  /** Move Back to New */
  MoveBackToNew: 'MoveBackToNew',
  /** Reject Invoice */
  Reject: 'Reject',
  /** Undo Approval */
  UndoApproval: 'UndoApproval'
} as const;

export type InvoiceOptionsEnum = typeof InvoiceOptionsEnum[keyof typeof InvoiceOptionsEnum];
export type InvoicePaymentOrCreditInput = {
  /** Amount of the Payment or Credit. */
  amount: Scalars['String']['input'];
  /** Date and time when this Payment or Credit was deleted. */
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  /** Date of the Payment. */
  markPaidAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Memo field for this Payment, can be used to add details to it. */
  memo?: InputMaybe<Scalars['String']['input']>;
  /** The PaymentMethod used for this Payment. */
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  /** Specify if this is a Payment or a Credit. */
  type: Scalars['String']['input'];
};

export type InvoiceRateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type InvoiceRateTypeInput = {
  type: RateTypeEnum;
};

/** Status Actions for invoice dashboard */
export const InvoiceStatusActionsEnum = {
  /** Approve */
  Approve: 'Approve',
  /** Mark Paid */
  MarkPaid: 'MarkPaid',
  /** Send */
  Send: 'Send',
  /** Send Disabled */
  SendDisabled: 'SendDisabled'
} as const;

export type InvoiceStatusActionsEnum = typeof InvoiceStatusActionsEnum[keyof typeof InvoiceStatusActionsEnum];
/** Invoice Status Groups */
export const InvoiceStatusGroupEnum = {
  /** Action Required */
  ActionRequired: 'ActionRequired',
  /** Approved */
  Approved: 'Approved',
  /** Done */
  Done: 'Done',
  /** New */
  New: 'New',
  /** Pending */
  Pending: 'Pending',
  /** Sent */
  Sent: 'Sent'
} as const;

export type InvoiceStatusGroupEnum = typeof InvoiceStatusGroupEnum[keyof typeof InvoiceStatusGroupEnum];
/** Invoice Transaction Type */
export const InvoiceTransactionPaymentMethodEnum = {
  /** ACH */
  ACH: 'ACH',
  /** American Express */
  AmericanExpress: 'AmericanExpress',
  /** Cash */
  Cash: 'Cash',
  /** Check */
  Check: 'Check',
  /** Credit Card */
  CreditCard: 'CreditCard',
  /** Debit Card */
  DebitCard: 'DebitCard',
  /** Discover */
  Discover: 'Discover',
  /** MasterCard */
  MasterCard: 'MasterCard',
  /** Visa */
  Visa: 'Visa'
} as const;

export type InvoiceTransactionPaymentMethodEnum = typeof InvoiceTransactionPaymentMethodEnum[keyof typeof InvoiceTransactionPaymentMethodEnum];
/** Invoice usage context enum */
export const InvoiceUsageContextEnum = {
  /** Modifying fees on the invoice of a release job */
  ReleaseFees: 'ReleaseFees'
} as const;

export type InvoiceUsageContextEnum = typeof InvoiceUsageContextEnum[keyof typeof InvoiceUsageContextEnum];
/** Sort options input for invoices field */
export type InvoicesSortInput = {
  /** Order in which to sort invoices */
  ascending: Scalars['Boolean']['input'];
  /** Column on which to sort invoices */
  column: InvoicesSortableColumn;
};

/** Invoices Sortable Column Enum Type */
export const InvoicesSortableColumn = {
  CreatedAt: 'CreatedAt',
  JobId: 'JobId',
  SentAt: 'SentAt',
  TotalAmount: 'TotalAmount'
} as const;

export type InvoicesSortableColumn = typeof InvoicesSortableColumn[keyof typeof InvoicesSortableColumn];
export type JobCallerInput = {
  /** Name of the customer. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the customer. */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
};

export type JobClaimInput = {
  /** The insurance claim number */
  number?: InputMaybe<Scalars['String']['input']>;
};

export type JobCustomerInput = {
  /** Customer's Claim Number */
  claimNumber?: InputMaybe<Scalars['String']['input']>;
  /** Email address of the Customer */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: please use name instead */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Member Number of the Customer */
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  /** First and last name of the Customer */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the Customer. */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Is the customer the driver of the vehicle? */
  vehicleDriver?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the customer the owner of the vehicle? */
  vehicleOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobCustomerPaymentInput = {
  /** Payment amount that was paid by customer */
  paymentAmount?: InputMaybe<Scalars['String']['input']>;
  /** Stripe payment id done by Agent */
  paymentId?: InputMaybe<Scalars['String']['input']>;
  /** Type of payment selected by customer */
  paymentType?: InputMaybe<Scalars['String']['input']>;
};

export type JobCustomerRateInput = {
  classType?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['ID']['input'];
  coverageProgram?: InputMaybe<Scalars['String']['input']>;
  coverageResult: Scalars['String']['input'];
  coveredCost?: InputMaybe<Scalars['Float']['input']>;
  coveredDistance?: InputMaybe<Scalars['Float']['input']>;
  jobId: Scalars['ID']['input'];
  jobScheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
  pccCoverageId: Scalars['ID']['input'];
  serviceLocation: JobCustomerRateLocationInput;
  serviceType: Scalars['String']['input'];
  symptom: Scalars['String']['input'];
  towMileage?: InputMaybe<Scalars['Float']['input']>;
  vehicle: JobCustomerRateVehicleInput;
};

export type JobCustomerRateLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  locationType?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type JobCustomerRateResponseInput = {
  caseId: Scalars['Int']['input'];
  defaultRate: Scalars['Boolean']['input'];
  rateAmount: Scalars['Float']['input'];
  rateGeneratedAt: Scalars['DateTime']['input'];
  rateId?: InputMaybe<Scalars['String']['input']>;
  traceId?: InputMaybe<Scalars['String']['input']>;
};

export type JobCustomerRateVehicleInput = {
  equipment?: InputMaybe<Scalars['String']['input']>;
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  strandedVehicleClassType?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type JobEtaInputType = {
  /** Current ETA */
  current?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Filter for limiting jobs based on custom criteria */
export const JobFilter = {
  /** Accident Tow jobs for companies participating in Accident Management service */
  AccidentManagement: 'AccidentManagement'
} as const;

export type JobFilter = typeof JobFilter[keyof typeof JobFilter];
/** The Job Form Job Input Type. */
export type JobFormJobInput = {
  /** The ID of the coverage to be assigned to the job. */
  coverageId?: InputMaybe<Scalars['ID']['input']>;
  /** Customer details */
  customer?: InputMaybe<JobCustomerInput>;
  /** Job Customer Lookup Fields */
  customerLookup?: InputMaybe<CustomerLookupInput>;
  /** Customer Payment details */
  customerPayment?: InputMaybe<JobCustomerPaymentInput>;
  /** Fleet site */
  fleetSite?: InputMaybe<FleetSiteInput>;
  /** The ID of the job to be updated. */
  id: Scalars['ID']['input'];
  /** Location details */
  location?: InputMaybe<JobLocationInput>;
  /** Notes details */
  notes?: InputMaybe<NotesInput>;
  /** Policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** Reference number */
  refNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected policy used to run a coverage check for this job. */
  selectedPolicy?: InputMaybe<SelectedPolicyInput>;
  /** Poilcy driver used to run a coverage check for this job. */
  selectedPolicyDriver?: InputMaybe<SelectedPolicyDriverInput>;
  /** Policy vehicle used to run a coverage check for this job. */
  selectedPolicyVehicle?: InputMaybe<SelectedPolicyVehicleInput>;
  /** Service details */
  service?: InputMaybe<ServiceInput>;
  /** Job Texts details */
  texts?: InputMaybe<JobTextsInput>;
  /** Stranded Vehicle details */
  vehicle?: InputMaybe<StrandedVehicleInput>;
};

/** Filter for job history items */
export type JobHistoryFilterInput = {
  /** JobHistoryItem status */
  includeStatuses?: InputMaybe<Array<JobHistoryStatusEnum>>;
  /** JobHistoryItem type */
  includeTypes?: InputMaybe<Array<JobHistoryTypeEnum>>;
};

/** Job History Icon Enum Type */
export const JobHistoryIconEnumType = {
  /** Agent Icon */
  AgentIcon: 'AgentIcon',
  /** Alarm Snooze */
  AlarmSnooze: 'AlarmSnooze',
  /** Check */
  Check: 'Check',
  /** Comment */
  Comment: 'Comment',
  /** Credit Card */
  CreditCard: 'CreditCard',
  /** Exclamation Triangle */
  ExclamationTriangle: 'ExclamationTriangle',
  /** Pencil */
  Pencil: 'Pencil',
  /** Screen Pop Icon */
  ScreenPopIcon: 'ScreenPopIcon',
  /** Signal Stream */
  SignalStream: 'SignalStream',
  /** Yellow Check */
  YellowCheck: 'YellowCheck'
} as const;

export type JobHistoryIconEnumType = typeof JobHistoryIconEnumType[keyof typeof JobHistoryIconEnumType];
/** Job History Status Enum */
export const JobHistoryStatusEnum = {
  /** Accepted */
  Accepted: 'Accepted',
  /** Assigned */
  Assigned: 'Assigned',
  /** Auto Assigning */
  AutoAssigning: 'AutoAssigning',
  /** Canceled */
  Canceled: 'Canceled',
  /** Completed */
  Completed: 'Completed',
  /** Created */
  Created: 'Created',
  /** Deleted */
  Deleted: 'Deleted',
  /** Dispatched */
  Dispatched: 'Dispatched',
  /** Draft */
  Draft: 'Draft',
  /** En Route */
  EnRoute: 'EnRoute',
  /** GOA */
  GOA: 'GOA',
  /** GOA Requested */
  GOARequested: 'GOARequested',
  /** Invoice Approval Undone */
  InvoiceApprovalUndone: 'InvoiceApprovalUndone',
  /** Invoice Approved */
  InvoiceApproved: 'InvoiceApproved',
  /** Invoice Closed */
  InvoiceClosed: 'InvoiceClosed',
  /** Invoice Edited */
  InvoiceEdited: 'InvoiceEdited',
  /** Invoice Edited - GOA Cost */
  InvoiceEditedGoaCost: 'InvoiceEditedGoaCost',
  /** Invoice Edited - VCC Payment */
  InvoiceEditedVccPayment: 'InvoiceEditedVccPayment',
  /** Invoice Paid */
  InvoicePaid: 'InvoicePaid',
  /** Invoice Sent */
  InvoiceSent: 'InvoiceSent',
  /** On Site */
  OnSite: 'OnSite',
  /** Provider Released */
  ProviderReleased: 'ProviderReleased',
  /** Reassign Requested */
  ReassignRequested: 'ReassignRequested',
  /** Reassigned */
  Reassigned: 'Reassigned',
  /** Referred */
  Referred: 'Referred',
  /** Rejected */
  Rejected: 'Rejected',
  /** Stored */
  Stored: 'Stored',
  /** Submitted */
  Submitted: 'Submitted',
  /** Tow Destination */
  TowDestination: 'TowDestination',
  /** Towing */
  Towing: 'Towing',
  /** Unpaid */
  Unpaid: 'Unpaid',
  /** Unsuccessful */
  Unsuccessful: 'Unsuccessful',
  /** Unsuccessful Requested */
  UnsuccessfulRequested: 'UnsuccessfulRequested'
} as const;

export type JobHistoryStatusEnum = typeof JobHistoryStatusEnum[keyof typeof JobHistoryStatusEnum];
/** Job History Type Enum */
export const JobHistoryTypeEnum = {
  /** JobHistoryItem::AgentChanged */
  AgentChanged: 'AgentChanged',
  /** JobHistoryItem::Alert */
  Alert: 'Alert',
  /** JobHistoryItem::AuctionDisabled */
  AuctionDisabled: 'AuctionDisabled',
  /** JobHistoryItem::AutoAssign */
  AutoAssign: 'AutoAssign',
  /** JobHistoryItem::CustomerPayment */
  CustomerPayment: 'CustomerPayment',
  /** JobHistoryItem::DetailsEmailed */
  DetailsEmailed: 'DetailsEmailed',
  /** JobHistoryItem::EtaUpdate */
  EtaUpdate: 'EtaUpdate',
  /** JobHistoryItem::InfoChanged */
  InfoChanged: 'InfoChanged',
  /** JobHistoryItem::Invoice */
  Invoice: 'Invoice',
  /** JobHistoryItem::LocationUpdated */
  LocationUpdated: 'LocationUpdated',
  /** JobHistoryItem::MotorClubRequestExpired */
  MotorClubRequestExpired: 'MotorClubRequestExpired',
  /** JobHistoryItem::MotorClubRequestRejected */
  MotorClubRequestRejected: 'MotorClubRequestRejected',
  /** JobHistoryItem::NpsScoreChanged */
  NpsScoreChanged: 'NpsScoreChanged',
  /** JobHistoryItem::PaymentReceipt */
  PaymentReceipt: 'PaymentReceipt',
  /** JobHistoryItem::RateAgreementUpdated */
  RateAgreementUpdated: 'RateAgreementUpdated',
  /** JobHistoryItem::ReturnToHQ */
  ReturnToHQ: 'ReturnToHQ',
  /** JobHistoryItem::RscCallback */
  RscCallback: 'RscCallback',
  /** JobHistoryItem::Scheduled */
  Scheduled: 'Scheduled',
  /** JobHistoryItem::ScreenPop */
  ScreenPop: 'ScreenPop',
  /** JobHistoryItem::ServiceChange */
  ServiceChange: 'ServiceChange',
  /** JobHistoryItem::Sms */
  Sms: 'Sms',
  /** JobHistoryItem::StatusUpdated */
  StatusUpdated: 'StatusUpdated',
  /** JobHistoryItem::SurveySubmitted */
  SurveySubmitted: 'SurveySubmitted',
  /** JobHistoryItem::Task */
  Task: 'Task',
  /** JobHistoryItem::VCCPayment */
  VCCPayment: 'VCCPayment'
} as const;

export type JobHistoryTypeEnum = typeof JobHistoryTypeEnum[keyof typeof JobHistoryTypeEnum];
export type JobIdInput = {
  id: Scalars['ID']['input'];
};

/** Job input for creating a mobile web job by clients */
export type JobInput = {
  /** Customer */
  customer?: InputMaybe<CustomerInput>;
  /** Job notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Pickup location */
  pickupLocation?: InputMaybe<LatitudeLongitudeInput>;
  /** 'Job service contract number. Can be used for a Profile Search if search type is available for the client.' */
  serviceContractNumber?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle */
  vehicle?: InputMaybe<CreateMobileIntakeJobByClientVehicle>;
};

export type JobLocationInput = {
  /** Customer may optionally specify a separate contact who will be with the vehicle at the service location. */
  dropoffContact?: InputMaybe<ContactInput>;
  dropoffLocation?: InputMaybe<LocationInput>;
  /** Customer may optionally specify a separate contact who will be with the vehicle at the service location. */
  pickupContact?: InputMaybe<ContactInput>;
  serviceLocation?: InputMaybe<LocationInput>;
};

/** Send job location sms input */
export type JobLocationSmsInput = {
  /** Phone number to send request location SMS */
  phone: Scalars['E164PhoneNumber']['input'];
  /** Service location type name */
  serviceLocationType?: InputMaybe<ServiceLocationTypeEnum>;
};

/** Dashboard options available for job */
export const JobOption = {
  Cancel: 'Cancel',
  ConfirmComplete: 'ConfirmComplete',
  DeleteDraft: 'DeleteDraft',
  Duplicate: 'Duplicate',
  EmailDetails: 'EmailDetails',
  ManageStatus: 'ManageStatus',
  MarkAsGoa: 'MarkAsGoa',
  MarkCompleted: 'MarkCompleted',
  MarkUnsuccessful: 'MarkUnsuccessful',
  PermanentlyDelete: 'PermanentlyDelete',
  Reassign: 'Reassign',
  RedispatchJob: 'RedispatchJob',
  RedispatchJobPending: 'RedispatchJobPending',
  ReleaseFees: 'ReleaseFees',
  ReportUnsuccessful: 'ReportUnsuccessful'
} as const;

export type JobOption = typeof JobOption[keyof typeof JobOption];
export type JobPartnerDepartmentInput = {
  /** Department name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobPartnerDriverInput = {
  /** Driver name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobPartnerInput = {
  department?: InputMaybe<JobPartnerDepartmentInput>;
  driver?: InputMaybe<JobPartnerDriverInput>;
  trailer?: InputMaybe<JobPartnerTrailerInput>;
  vehicle?: InputMaybe<JobPartnerVehicleInput>;
};

export type JobPartnerTrailerInput = {
  /** Trailer name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobPartnerVehicleInput = {
  /** Vehicle name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A JobStatus. A Job flow will push the Job through different statuses. */
export const JobStatus = {
  /** Accept Or Reject */
  AcceptOrReject: 'AcceptOrReject',
  /** Accepted */
  Accepted: 'Accepted',
  /** Assigned */
  Assigned: 'Assigned',
  /** Auto Assigning */
  AutoAssigning: 'AutoAssigning',
  /** Canceled */
  Canceled: 'Canceled',
  /** Collecting */
  Collecting: 'Collecting',
  /** Completed */
  Completed: 'Completed',
  /** Created */
  Created: 'Created',
  /** Deleted */
  Deleted: 'Deleted',
  /** Dispatched */
  Dispatched: 'Dispatched',
  /** Draft */
  Draft: 'Draft',
  /** ETA Extended */
  ETAExtended: 'ETAExtended',
  /** ETA Rejected */
  ETARejected: 'ETARejected',
  /** En Route */
  EnRoute: 'EnRoute',
  /** Expired */
  Expired: 'Expired',
  /** GOA */
  GOA: 'GOA',
  /** GOA Requested */
  GOARequested: 'GOARequested',
  /** Initial */
  Initial: 'Initial',
  /** Mark Paid */
  MarkPaid: 'MarkPaid',
  /** On Site */
  OnSite: 'OnSite',
  /** Payment Pending */
  PaymentPending: 'PaymentPending',
  /** Pending */
  Pending: 'Pending',
  /** Predraft */
  Predraft: 'Predraft',
  /** Provider Released */
  ProviderReleased: 'ProviderReleased',
  /** Reassign */
  Reassign: 'Reassign',
  /** Reassign Requested */
  ReassignRequested: 'ReassignRequested',
  /** Reassigned */
  Reassigned: 'Reassigned',
  /** Referred */
  Referred: 'Referred',
  /** Rejected */
  Rejected: 'Rejected',
  /** Released */
  Released: 'Released',
  /** Scheduled */
  Scheduled: 'Scheduled',
  /** Stored */
  Stored: 'Stored',
  /** Submitted */
  Submitted: 'Submitted',
  /** Tow Destination */
  TowDestination: 'TowDestination',
  /** Towing */
  Towing: 'Towing',
  /** Unassigned */
  Unassigned: 'Unassigned',
  /** Unpaid */
  Unpaid: 'Unpaid',
  /** Unsuccessful */
  Unsuccessful: 'Unsuccessful',
  /** Unsuccessful Requested */
  UnsuccessfulRequested: 'UnsuccessfulRequested'
} as const;

export type JobStatus = typeof JobStatus[keyof typeof JobStatus];
/** When to send emails for each selected job status. */
export const JobStatusEmailFrequencyEnum = {
  /** Send emails for all jobs */
  all: 'all',
  /** Send emails for jobs in which the user is the dispatcher */
  dispatcher: 'dispatcher',
  /** Don't send any emails */
  none: 'none'
} as const;

export type JobStatusEmailFrequencyEnum = typeof JobStatusEmailFrequencyEnum[keyof typeof JobStatusEmailFrequencyEnum];
export type JobStatusEmailPreferencesInput = {
  /** ETA Provided */
  accepted: Scalars['Boolean']['input'];
  /** Assigned a Provider */
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** Job Canceled */
  canceled: Scalars['Boolean']['input'];
  /** Job Completed */
  completed: Scalars['Boolean']['input'];
  /** Job Created or Scheduled */
  created: Scalars['Boolean']['input'];
  /** Job drafted */
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  /** En Route */
  enRoute: Scalars['Boolean']['input'];
  /** ETA Extended */
  etaExtended: Scalars['Boolean']['input'];
  /** Job GOA */
  goa: Scalars['Boolean']['input'];
  /** On Site */
  onSite: Scalars['Boolean']['input'];
  /** Pending assignment */
  pending?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reassigned */
  reassigned: Scalars['Boolean']['input'];
  /** Tow Destination */
  towDestination: Scalars['Boolean']['input'];
  /** Towing */
  towing: Scalars['Boolean']['input'];
};

/** Each value represents a group of `JobStatuses` to query on. */
export const JobStatusGroup = {
  /** Active Jobs */
  Active: 'Active',
  /** All Jobs */
  All: 'All',
  /** Done Jobs */
  Done: 'Done',
  /** Draft Jobs */
  Draft: 'Draft',
  /** Map Jobs */
  Map: 'Map'
} as const;

export type JobStatusGroup = typeof JobStatusGroup[keyof typeof JobStatusGroup];
/** Valid categories for job termination request */
export const JobTerminationCategoryEnumType = {
  /** Customer Canceling - Customer called to cancel */
  CustomerCanceling: 'CustomerCanceling',
  /** Swoop Dispatcher Canceling - Swoop Dispatch deciding to reassign job */
  DispatcherCanceling: 'DispatcherCanceling',
  /** Gone On Arrival (GOA) - Customer not on site */
  GoneOnArrival: 'GoneOnArrival',
  /** Incorrect equipment */
  IncorrectEquipment: 'IncorrectEquipment',
  /** Incorrect service */
  IncorrectService: 'IncorrectService',
  /** Location issue */
  LocationIssue: 'LocationIssue',
  /** Provider Canceling - Provider needs to cancel */
  ProviderCanceling: 'ProviderCanceling',
  /** Service attempted but failed */
  ServiceAttemptedButFailed: 'ServiceAttemptedButFailed',
  /** Service Unsuccessful - Customer needs a different service */
  ServiceUnsuccessful: 'ServiceUnsuccessful',
  /** Tow destination closed */
  TowDestinationClosed: 'TowDestinationClosed',
  /** Tow destination refusal */
  TowDestinationRefusal: 'TowDestinationRefusal'
} as const;

export type JobTerminationCategoryEnumType = typeof JobTerminationCategoryEnumType[keyof typeof JobTerminationCategoryEnumType];
/** Valid states for job termination request */
export const JobTerminationStatusEnumType = {
  /** Cancel the job */
  Canceled: 'Canceled',
  /** Job GOA */
  GOA: 'GOA',
  /** Reassign the job */
  Reassigned: 'Reassigned',
  /** Job Unsuccessful */
  Unsuccessful: 'Unsuccessful'
} as const;

export type JobTerminationStatusEnumType = typeof JobTerminationStatusEnumType[keyof typeof JobTerminationStatusEnumType];
export type JobTextsInput = {
  sendCouponSms?: InputMaybe<Scalars['Boolean']['input']>;
  sendDriverTracking?: InputMaybe<Scalars['Boolean']['input']>;
  sendEtaUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  sendLocation?: InputMaybe<Scalars['Boolean']['input']>;
  sendPaymentSms?: InputMaybe<Scalars['Boolean']['input']>;
  sendReview?: InputMaybe<Scalars['Boolean']['input']>;
  sendTextsToPickup?: InputMaybe<Scalars['Boolean']['input']>;
  sendUpdates?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Job Type */
export const JobType = {
  /** FleetInHouseJob */
  FleetInHouseJob: 'FleetInHouseJob',
  /** FleetManagedJob */
  FleetManagedJob: 'FleetManagedJob',
  /** FleetMotorClubJob */
  FleetMotorClubJob: 'FleetMotorClubJob',
  /** RescueJob */
  RescueJob: 'RescueJob'
} as const;

export type JobType = typeof JobType[keyof typeof JobType];
export type JobsFiltersInput = {
  /** DateTime range filter for job created time */
  createdAt?: InputMaybe<DateTimeRangeInput>;
  /** DateTime range filter for the last time a job status was changed */
  lastStatusChangedAt?: InputMaybe<DateTimeRangeInput>;
  /** Show MotorClub jobs */
  motorClub?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by po number */
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Filter by policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** Search String */
  query?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<JobStatusGroup>;
  /** Dashboard tab, overrides the states filter */
  tab?: InputMaybe<DashboardTabType>;
  /** User-configured dashboard filters */
  useUserFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobsSortInput = {
  ascending: Scalars['Boolean']['input'];
  column: SortableColumns;
};

/** Autogenerated input type of KeepAlive */
export type KeepAliveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Language for company */
export const LanguageEnum = {
  /** german */
  de: 'de',
  /** english */
  en: 'en'
} as const;

export type LanguageEnum = typeof LanguageEnum[keyof typeof LanguageEnum];
export type LatitudeLongitudeInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type LineItemJobInput = {
  id: Scalars['ID']['input'];
};

export type LocationInput = {
  /** Address that the customer inputs. Should be formatted like as "379 S Van Ness Ave, San Francisco, CA 94103, USA". */
  address?: InputMaybe<Scalars['String']['input']>;
  /** City. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** ISO 3166-1 alpha-2 country code. */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Location is exact and has lat/lng to high accuracy */
  exact?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID returned from Google Places API. See below for more information on Locations. */
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  /** Location Id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Latitude. */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** Longitude. */
  lng?: InputMaybe<Scalars['Float']['input']>;
  /** Location Source type */
  locationSourceType?: InputMaybe<LocationSourceType>;
  /** See below section for more information on Location Types. */
  locationType?: InputMaybe<Scalars['LocationTypeName']['input']>;
  /** Location Type of the location */
  locationTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Postal code. */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Site Id for the drop off location */
  siteId?: InputMaybe<Scalars['ID']['input']>;
  /** State/province. */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Street address. */
  street?: InputMaybe<Scalars['String']['input']>;
  /**
   * Street name.
   * @deprecated Use street argument instead.
   */
  streetName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Street number.
   * @deprecated Use street argument instead.
   */
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  /** Updated time for the location */
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

/** Enumerated values for Location Source Type */
export const LocationSourceType = {
  /** Autocomplete */
  Autocomplete: 'Autocomplete',
  /** CMW: Address Search */
  CMW_Address_Search: 'CMW_Address_Search',
  /** CMW: List View */
  CMW_List_View: 'CMW_List_View',
  /** CMW: Map View */
  CMW_Map_View: 'CMW_Map_View',
  /** Client API */
  Client_API: 'Client_API',
  /** Dropped Pin */
  Dropped_Pin: 'Dropped_Pin'
} as const;

export type LocationSourceType = typeof LocationSourceType[keyof typeof LocationSourceType];
export type LocationTypeInput = {
  /** Location latitude */
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  /** Location latitude */
  lat?: InputMaybe<Scalars['Float']['input']>;
  /** Location longitude */
  lng?: InputMaybe<Scalars['Float']['input']>;
  /** Site SSDID */
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of Logout */
export type LogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The SSID of the user being logged out */
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of ManagerApproveInvoice */
export type ManagerApproveInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being approved. */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of ManagerRejectInvoice */
export type ManagerRejectInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being rejected. */
  id: Scalars['ID']['input'];
  /** include notes for rejection */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** the selected rejection reason */
  rejectReasonId: Scalars['ID']['input'];
};

/** Autogenerated input type of ManagerUndoApproveInvoice */
export type ManagerUndoApproveInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being rejected. */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of MarkPaidInvoice */
export type MarkPaidInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice to mark paid */
  id: Scalars['ID']['input'];
};

export type MemberNumberInput = {
  /** Member number */
  memberNumber: Scalars['String']['input'];
};

export type MfaRequestInput = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
  strategy: Scalars['String']['input'];
};

/** Mileage rounding options for partner rates */
export const MileageRoundingEnum = {
  /** nearest */
  nearest: 'nearest',
  /** nearest_tenth */
  nearest_tenth: 'nearest_tenth',
  /** up */
  up: 'up'
} as const;

export type MileageRoundingEnum = typeof MileageRoundingEnum[keyof typeof MileageRoundingEnum];
/** Autogenerated input type of MoveBackToNewInvoice */
export type MoveBackToNewInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice to move back to new */
  id: Scalars['ID']['input'];
};

export type NameAddressVehicleInput = {
  /** Customer Address */
  address: Scalars['String']['input'];
  /** Customer City */
  city: Scalars['String']['input'];
  /** Customer First Name */
  firstName: Scalars['String']['input'];
  /** Customer Last Name */
  lastName: Scalars['String']['input'];
  /** Customer Postal Code */
  postalCode: Scalars['String']['input'];
  /** Customer Region */
  region: Scalars['String']['input'];
  /** Vehicle Make */
  vehicleMake: Scalars['String']['input'];
  /** Vehicle Model */
  vehicleModel: Scalars['String']['input'];
  /** Vehicle Year */
  vehicleYear: Scalars['Int']['input'];
};

export type NamePhoneNumberInput = {
  /** Customer Last Name */
  lastName: Scalars['String']['input'];
  /** Customer Phone number */
  phoneNumber: Scalars['E164PhoneNumber']['input'];
};

export type NamePhoneNumberVehicleInput = {
  /** Customer First Name */
  firstName: Scalars['String']['input'];
  /** Customer Last Name */
  lastName: Scalars['String']['input'];
  /** Customer Phone Number */
  phoneNumber: Scalars['E164PhoneNumber']['input'];
  /** Vehicle Make */
  vehicleMake: Scalars['String']['input'];
  /** Vehicle Model */
  vehicleModel: Scalars['String']['input'];
  /** Vehicle Year */
  vehicleYear: Scalars['Int']['input'];
};

export type NamePostalCodeInput = {
  /** Customer Last Name */
  lastName: Scalars['String']['input'];
  /** Customer Postal Code */
  postalCode: Scalars['String']['input'];
};

export type NameRegionPostalCodeInput = {
  /** Customer First Name */
  firstName: Scalars['String']['input'];
  /** Customer Last Name */
  lastName: Scalars['String']['input'];
  /** Customer Postal Code */
  postalCode: Scalars['String']['input'];
  /** Customer Region */
  region: Scalars['String']['input'];
};

export type NotesInput = {
  /** Client notes about the Job. */
  client?: InputMaybe<Scalars['String']['input']>;
  /** Notes about the customer's situation that will be passed to the service provider. This is an optional field. */
  customer?: InputMaybe<Scalars['String']['input']>;
  /** Internal notes about the Job. */
  internal?: InputMaybe<Scalars['String']['input']>;
  /** Invoice notes about the Job. */
  invoice?: InputMaybe<Scalars['String']['input']>;
};

/** Subscription channels for notification codes. */
export const NotificationChannelEnum = {
  /** User will receive a phone call */
  Call: 'Call',
  /** User will receive a text message */
  Text: 'Text'
} as const;

export type NotificationChannelEnum = typeof NotificationChannelEnum[keyof typeof NotificationChannelEnum];
/** Subscribed notification events for provider users. */
export const NotificationCodeEnum = {
  /** Job has been dispatched to driver */
  dispatched_to_driver: 'dispatched_to_driver',
  /** ETA has been accepted */
  eta_accepted: 'eta_accepted',
  /** New job created */
  new_job: 'new_job'
} as const;

export type NotificationCodeEnum = typeof NotificationCodeEnum[keyof typeof NotificationCodeEnum];
/** Attributes for creating a notification setting */
export type NotificationSettingInput = {
  /** Channels to be subscribed to */
  notificationChannels?: InputMaybe<Array<NotificationChannelEnum>>;
  /** Job events to be notified of */
  notificationCode: NotificationCodeEnum;
};

export type OpenCloseTimeInput = {
  /** Closing Time */
  close?: InputMaybe<Scalars['DateTime']['input']>;
  /** Opening Time */
  open?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Autogenerated input type of PartnerConfirmInvoicePaid */
export type PartnerConfirmInvoicePaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice for payment confirmation */
  id: Scalars['ID']['input'];
};

export type PartnerVehicleHide = {
  /** Filter out PartnerVehicles without Drivers */
  noDriver?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter out PartnerVehicles with off-duty Drivers */
  offDutyDriver?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartnerVehicleInput = {
  /** Vehicle ClassType Name */
  classType?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  /** Vehicle Make Name */
  make?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Model Name */
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** Vehicle Year */
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type PasswordRequestInput = {
  lockout?: InputMaybe<Scalars['Boolean']['input']>;
  userInput: Scalars['String']['input'];
};

export type PasswordResetInput = {
  id: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

/** Payment type for release fees */
export const PaymentTypeEnum = {
  /** Cash */
  Cash: 'Cash',
  /** Check */
  Check: 'Check',
  /** No Fees */
  NoFees: 'NoFees',
  /** Physical Card */
  PhysicalCard: 'PhysicalCard',
  /** VCC */
  VCC: 'VCC'
} as const;

export type PaymentTypeEnum = typeof PaymentTypeEnum[keyof typeof PaymentTypeEnum];
/** PayoutInterval Type */
export const PayoutIntervalEnum = {
  /** Daily */
  daily: 'daily',
  /** Monthly (1st of Month) */
  monthly: 'monthly',
  /** Weekly (Friday) */
  weekly: 'weekly'
} as const;

export type PayoutIntervalEnum = typeof PayoutIntervalEnum[keyof typeof PayoutIntervalEnum];
export type PhoneNumberInput = {
  /** Customer Phone Number */
  phoneNumber: Scalars['String']['input'];
};

/** Wrapper for all Policy Lookup search options. */
export type PolicyLookupSearchTermsInput = {
  /** Search policies by claim number. */
  claimNumberInput?: InputMaybe<ClaimNumberInput>;
  /** Search policies by company name and postal code. */
  companyNamePostalCodeInput?: InputMaybe<CompanyNamePostalCodeInput>;
  /** Search policies by Full VIN. */
  fullVinInput?: InputMaybe<FullVINInput>;
  /** Search policies by government id (aka social security number in US). */
  governmentIdInput?: InputMaybe<GovernmentIdInput>;
  /** Search policies by member number. */
  memberNumberInput?: InputMaybe<MemberNumberInput>;
  /** Search policies by name, address and vehicle information. */
  nameAddressVehicleInput?: InputMaybe<NameAddressVehicleInput>;
  /** Search policies by name and phone number. */
  namePhoneNumberInput?: InputMaybe<NamePhoneNumberInput>;
  /** Search policies by name, phone number and vehicle information. */
  namePhoneNumberVehicleInput?: InputMaybe<NamePhoneNumberVehicleInput>;
  /** Search policies by name and postal code. */
  namePostalCodeInput?: InputMaybe<NamePostalCodeInput>;
  /** Search policies by name, region (aka state) and postal code. */
  nameRegionPostalCodeInput?: InputMaybe<NameRegionPostalCodeInput>;
  /** Search policies by phone number. */
  phoneNumberInput?: InputMaybe<PhoneNumberInput>;
  /** Search policies by policy number. */
  policyNumberInput?: InputMaybe<PolicyNumberInput>;
  /** Search policies by service contract number. */
  serviceContractNumberInput?: InputMaybe<ServiceContractNumberInput>;
  /** Search policies by unit number. */
  unitNumberInput?: InputMaybe<UnitNumberInput>;
  /** Search policies by VIN. */
  vinInput?: InputMaybe<VINInput>;
};

export type PolicyNumberInput = {
  /** Policy number */
  policyNumber: Scalars['String']['input'];
};

/** An enumeration of candidate preferred statuses. */
export const PreferredEnum = {
  /** Client Preferred */
  client: 'client',
  /** Client Site Preferred */
  client_site: 'client_site',
  /** Not Preferred */
  not: 'not'
} as const;

export type PreferredEnum = typeof PreferredEnum[keyof typeof PreferredEnum];
/** Autogenerated input type of PresignDocument */
export type PresignDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Enumerated values for Provider Billable */
export const ProviderBillable = {
  /** Fully Billable */
  fully_billable: 'fully_billable',
  /** Not Billable */
  not_billable: 'not_billable',
  /** Partially Billable */
  partially_billable: 'partially_billable'
} as const;

export type ProviderBillable = typeof ProviderBillable[keyof typeof ProviderBillable];
export type ProviderCompanyAddExistingSiteInput = {
  accountingEmail: Scalars['String']['input'];
  dispatchEmail: Scalars['String']['input'];
  fax?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location: LocationInput;
  locationCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  primaryContact?: InputMaybe<Scalars['String']['input']>;
  primaryEmail?: InputMaybe<Scalars['String']['input']>;
  primaryPhone?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  tireProgram?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vendorCode?: InputMaybe<Scalars['String']['input']>;
};

export type ProviderCompanyAddSiteToExistingProviderInput = {
  accountingEmail: Scalars['String']['input'];
  dispatchEmail: Scalars['String']['input'];
  fax?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location: LocationInput;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  primaryContact?: InputMaybe<Scalars['String']['input']>;
  primaryEmail?: InputMaybe<Scalars['String']['input']>;
  primaryPhone?: InputMaybe<Scalars['String']['input']>;
  sites: Array<SiteCompanyInput>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ProviderCompanyCreateInput = {
  accountingEmail: Scalars['String']['input'];
  dispatchEmail: Scalars['String']['input'];
  location: LocationInput;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  primaryContact?: InputMaybe<Scalars['String']['input']>;
  primaryEmail?: InputMaybe<Scalars['String']['input']>;
  primaryPhone?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAnswerInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
};

/** Input type for a single question answer */
export type QuestionResultsAnswerInput = {
  /** The answer for a question on a determined Job. */
  answer?: InputMaybe<Scalars['String']['input']>;
};

/** The answers to questions that are part of a Service */
export type QuestionResultsInput = {
  altTransBmw?: InputMaybe<QuestionResultsAnswerInput>;
  altTransport?: InputMaybe<QuestionResultsAnswerInput>;
  beenJumped?: InputMaybe<QuestionResultsAnswerInput>;
  bikeAttendedAtPickup?: InputMaybe<QuestionResultsAnswerInput>;
  bikeForksLocked?: InputMaybe<QuestionResultsAnswerInput>;
  bikeRolls?: InputMaybe<QuestionResultsAnswerInput>;
  carInNeutral?: InputMaybe<QuestionResultsAnswerInput>;
  extremeConditions?: InputMaybe<QuestionResultsAnswerInput>;
  gasolineType?: InputMaybe<QuestionResultsAnswerInput>;
  hasGps?: InputMaybe<QuestionResultsAnswerInput>;
  hasSpare?: InputMaybe<QuestionResultsAnswerInput>;
  isAllWheel?: InputMaybe<QuestionResultsAnswerInput>;
  isDrivable?: InputMaybe<QuestionResultsAnswerInput>;
  isRunning?: InputMaybe<QuestionResultsAnswerInput>;
  keysPresent?: InputMaybe<QuestionResultsAnswerInput>;
  keysWithBike?: InputMaybe<QuestionResultsAnswerInput>;
  leakingFluids?: InputMaybe<QuestionResultsAnswerInput>;
  lockedIn?: InputMaybe<QuestionResultsAnswerInput>;
  lowClearance?: InputMaybe<QuestionResultsAnswerInput>;
  missingTires?: InputMaybe<QuestionResultsAnswerInput>;
  movedToday?: InputMaybe<QuestionResultsAnswerInput>;
  nearPavement?: InputMaybe<QuestionResultsAnswerInput>;
  nearPavement15?: InputMaybe<QuestionResultsAnswerInput>;
  neutral?: InputMaybe<QuestionResultsAnswerInput>;
  phoneReceiveTexts?: InputMaybe<QuestionResultsAnswerInput>;
  pinPresent?: InputMaybe<QuestionResultsAnswerInput>;
  riskFactors?: InputMaybe<QuestionResultsAnswerInput>;
  safeLocation?: InputMaybe<QuestionResultsAnswerInput>;
  shuttle?: InputMaybe<QuestionResultsAnswerInput>;
  stopRunning?: InputMaybe<QuestionResultsAnswerInput>;
  tripInterrupt?: InputMaybe<QuestionResultsAnswerInput>;
  undercarriageDamage?: InputMaybe<QuestionResultsAnswerInput>;
  unlistedTrailer?: InputMaybe<QuestionResultsAnswerInput>;
  vip?: InputMaybe<QuestionResultsAnswerInput>;
  wasContacted?: InputMaybe<QuestionResultsAnswerInput>;
  whereKeys?: InputMaybe<QuestionResultsAnswerInput>;
  whichTire?: InputMaybe<QuestionResultsAnswerInput>;
  withVehicle?: InputMaybe<QuestionResultsAnswerInput>;
};

export const RateAgreementCapabilityType = {
  /** Service Capabilities */
  Service: 'Service'
} as const;

export type RateAgreementCapabilityType = typeof RateAgreementCapabilityType[keyof typeof RateAgreementCapabilityType];
export type RateAgreementInput = {
  anchorLocation: LocationInput;
  billingType: BillingType;
  dispatchEmail: Scalars['String']['input'];
  dispatchPhone: Scalars['E164PhoneNumber']['input'];
  name: Scalars['String']['input'];
  schedule: ScheduleInput;
  serviceProviderId: Scalars['ID']['input'];
  status: RateAgreementStatus;
};

export const RateAgreementStatus = {
  /** Rate agreement is active */
  Active: 'Active',
  /** Rate agreement is disabled */
  Hold: 'Hold'
} as const;

export type RateAgreementStatus = typeof RateAgreementStatus[keyof typeof RateAgreementStatus];
export type RateAgreementUpdateInput = {
  anchorLocation?: InputMaybe<LocationInput>;
  dispatchEmail?: InputMaybe<Scalars['String']['input']>;
  dispatchPhone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  excludedPostalCodes?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  postalCodes?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<ScheduleInput>;
  serviceCapabilities?: InputMaybe<Array<CapabilityInput>>;
  status?: InputMaybe<RateAgreementStatus>;
};

export type RateInput = {
  additionalItemId?: InputMaybe<Scalars['ID']['input']>;
  classTypeId?: InputMaybe<Scalars['ID']['input']>;
  effectiveAt?: InputMaybe<Scalars['DateTime']['input']>;
  equipmentId?: InputMaybe<Scalars['ID']['input']>;
  flat?: InputMaybe<Scalars['Float']['input']>;
  gone?: InputMaybe<Scalars['Float']['input']>;
  hookup?: InputMaybe<Scalars['Float']['input']>;
  hourly?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  milesDeadhead?: InputMaybe<Scalars['Float']['input']>;
  milesDeadheadFree?: InputMaybe<Scalars['Float']['input']>;
  milesEnroute?: InputMaybe<Scalars['Float']['input']>;
  milesEnrouteFree?: InputMaybe<Scalars['Float']['input']>;
  milesP2p?: InputMaybe<Scalars['Float']['input']>;
  milesP2pFree?: InputMaybe<Scalars['Float']['input']>;
  milesTowed?: InputMaybe<Scalars['Float']['input']>;
  milesTowedFree?: InputMaybe<Scalars['Float']['input']>;
  partialFee?: InputMaybe<Scalars['Float']['input']>;
  rateAgreementId?: InputMaybe<Scalars['ID']['input']>;
  serviceCodeId?: InputMaybe<Scalars['ID']['input']>;
  specialDolly?: InputMaybe<Scalars['Float']['input']>;
  storageDaily?: InputMaybe<Scalars['Float']['input']>;
  /** Rate type enum */
  type?: InputMaybe<RateTypeEnum>;
  vehicleCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

/** A RateType representation, used to change the RateType of an Invoice */
export const RateTypeEnum = {
  /** En Route + Hourly */
  EnRoutePlusHourly: 'EnRoutePlusHourly',
  /** Flat */
  Flat: 'Flat',
  /** Hourly (Port to Port) */
  HoursPortToPort: 'HoursPortToPort',
  /** Mileage (En Route) */
  MileageEnRoute: 'MileageEnRoute',
  /** Mileage (Towed) */
  MileageTowed: 'MileageTowed',
  /** Mileage (En Route + Towed) */
  MilesEnRouteAndTowed: 'MilesEnRouteAndTowed',
  /** Mileage (Port to Port) */
  MilesPortToPort: 'MilesPortToPort',
  /** Storage */
  Storage: 'Storage',
  /** Tesla */
  Tesla: 'Tesla'
} as const;

export type RateTypeEnum = typeof RateTypeEnum[keyof typeof RateTypeEnum];
/** filter arguments for rates */
export type RatesFiltersInput = {
  /** Account ID filter for rates */
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** Additional Item ID filter for rates */
  additionalItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Job ID filter for rates */
  jobId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by objects with or without rates present */
  present?: InputMaybe<Scalars['Boolean']['input']>;
  /** Rate Agreement ID filter for rates */
  rateAgreementId?: InputMaybe<Scalars['ID']['input']>;
  /** Service or Addon ID filter for rates */
  serviceCodeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Recommended Dropoff Location Input */
export type RecommendedDropoffLocationInput = {
  /** SSID of the Job */
  jobId: Scalars['ID']['input'];
  /** Vehicle Make Name */
  make?: InputMaybe<Scalars['String']['input']>;
  /** PCC Coverage ID */
  pccCoverageSwcid?: InputMaybe<Scalars['Int']['input']>;
  /** Time the Job was scheduled for */
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
  /** Service Location */
  serviceLocation?: InputMaybe<LocationInput>;
  /** Service Name */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** Symptom Name */
  symptomName?: InputMaybe<Scalars['String']['input']>;
};

export type RecommendedDropoffLocationTypesSelectorType = {
  /** Client Company SSID */
  companyId: Scalars['ID']['input'];
  /** Service Name */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** Symptom Name */
  symptomName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RecordLogin */
export type RecordLoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RegisterForPushNotifications */
export type RegisterForPushNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deviceToken: Scalars['String']['input'];
  endpointId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RejectInvoice */
export type RejectInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being rejected. */
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  rejectReason: Scalars['String']['input'];
};

/** Autogenerated input type of RejectTowDestinationSite */
export type RejectTowDestinationSiteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Job SSID */
  jobId: Scalars['ID']['input'];
  /** Reject Reason */
  rejectReason: TowDestinationRejectReasons;
  /** Site SSID */
  siteId: Scalars['ID']['input'];
};

/** Autogenerated input type of RequestCallback */
export type RequestCallbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  dispatchPhone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  job: RequestCallbackJobInput;
  secondaryPhone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
};

export type RequestCallbackJobInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of RequestJobCustomerRate */
export type RequestJobCustomerRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customerRateRequest: JobCustomerRateInput;
};

/** Autogenerated input type of RequestMfaToken */
export type RequestMfaTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mfaRequest: MfaRequestInput;
};

/** Autogenerated input type of RequestMoreTime */
export type RequestMoreTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job: RequestMoreTimeJobInput;
};

export type RequestMoreTimeJobInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Criteria to choose the Equipment required to fulfill Job. */
export type RequiredEquipmentLookupInput = {
  /** Client Company SSID */
  companyId: Scalars['ID']['input'];
  /** DriveTrain */
  drivetrain?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Make name */
  make: Scalars['String']['input'];
  /** Vehicle Model name */
  model: Scalars['String']['input'];
  /** Service Code */
  serviceCode: Scalars['String']['input'];
  /** Vehicle Type */
  vehicleType?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  passwordReset: PasswordResetInput;
};

/** Review Status Type */
export const ReviewStatusType = {
  /** In Progress */
  InProgress: 'InProgress',
  /** No Action Required */
  NoActionRequired: 'NoActionRequired',
  /** No Response */
  NoResponse: 'NoResponse',
  /** Resolved */
  Resolved: 'Resolved',
  /** Unresolved */
  Unresolved: 'Unresolved'
} as const;

export type ReviewStatusType = typeof ReviewStatusType[keyof typeof ReviewStatusType];
export type ReviewsFiltersInput = {
  /** Search String */
  query?: InputMaybe<Scalars['String']['input']>;
  /** User-configured reviews filters */
  useUserFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Role Type */
export const RoleType = {
  /** Manage the company settings, and can execute Dispatcher tasks as well */
  Admin: 'Admin',
  /** Answering Service */
  AnsweringService: 'AnsweringService',
  /** User has Amazon Connect access */
  CommunicationsIntegration: 'CommunicationsIntegration',
  /** Create and dispatch jobs to Drivers */
  Dispatcher: 'Dispatcher',
  /** Execute the jobs for a Provider Company */
  Driver: 'Driver',
  /** User works for a Client Company */
  Fleet: 'Fleet',
  /** User has access to insights reporting */
  Insights: 'Insights',
  /** User works for a Provider Company */
  Rescue: 'Rescue',
  /** User is a Swoop Root user */
  Root: 'Root',
  /** Grants access to the admin API */
  SuperFleetManaged: 'SuperFleetManaged',
  /** User is a Swoop dispatcher */
  SwoopDispatcher: 'SwoopDispatcher',
  /** Manage the company settings, and can execute Dispatcher tasks as well */
  admin: 'admin',
  /** Answering Service */
  answering_service: 'answering_service',
  /** User has Amazon Connect access */
  communications_integration: 'communications_integration',
  /** Create and dispatch jobs to Drivers */
  dispatcher: 'dispatcher',
  /** Execute the jobs for a Provider Company */
  driver: 'driver',
  /** User works for a Client Company */
  fleet: 'fleet',
  /** User has access to insights reporting */
  insights: 'insights',
  /** User works for a Provider Company */
  rescue: 'rescue',
  /** User is a Swoop Root user */
  root: 'root',
  /** Grants access to the admin API */
  super_fleet_managed: 'super_fleet_managed',
  /** User is a Swoop dispatcher */
  swoop_dispatcher: 'swoop_dispatcher'
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];
/** Autogenerated input type of SampleInvoice */
export type SampleInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoice: InvoiceInput;
};

/** Autogenerated input type of SaveStorageYard */
export type SaveStorageYardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Inputs for saving a storage yard rate agreement */
  storageYardAgreement: SaveStorageYardInputType;
};

/** Inputs to save a storage yard rate agreement */
export type SaveStorageYardInputType = {
  /** Storage Yard location */
  anchorLocation: LocationInput;
  /** Storage Yard email */
  dispatchEmail?: InputMaybe<Scalars['String']['input']>;
  /** Storage Yard Phone number */
  dispatchPhone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Storage Yard Rate Agreement id */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Storage Yard Name */
  name: Scalars['String']['input'];
};

export type ScheduleDayInput = {
  dayOfWeek: WeekdayEnum;
  endTime: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type ScheduleInput = {
  days?: InputMaybe<Array<ScheduleDayInput>>;
  open247: Scalars['Boolean']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Filters for the search criteria. */
export type SearchFilters = {
  /** Capabilities */
  capabilities?: InputMaybe<Scalars['String']['input']>;
  /** Dispatch Phone */
  dispatchPhone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Search Radius */
  searchRadius?: InputMaybe<SearchRadiusType>;
  serviceProviderId?: InputMaybe<Scalars['ID']['input']>;
};

/** Send latitute, longitude and distance in meters to use radius filter. */
export type SearchRadiusType = {
  /** Latitude */
  lat: Scalars['Float']['input'];
  /** Longitude */
  lng: Scalars['Float']['input'];
  /** distance for radius filter in meters */
  withinMeters: Scalars['Int']['input'];
};

export type SearchTermsInputType = {
  term: Scalars['String']['input'];
};

export type SelectedPolicyDriverInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Selected policy on a job. */
export type SelectedPolicyInput = {
  /** ID of the selected policy. */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SelectedPolicyVehicleInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of SendGetLocationSms */
export type SendGetLocationSmsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the job to send request location SMS for */
  jobId: Scalars['ID']['input'];
  /** Phone number to send request location SMS */
  phone: Scalars['E164PhoneNumber']['input'];
  /** Service location */
  serviceLocationType?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of SendInvoice */
export type SendInvoiceInput = {
  /** List of attached document IDs to set as invoice attachments */
  attachedDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice to send */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of SendJobLocationSms */
export type SendJobLocationSmsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the job to send request location SMS for */
  jobId: Scalars['ID']['input'];
  /** Send job location sms input */
  sendJobLocationSmsParams: JobLocationSmsInput;
};

export type ServiceContractNumberInput = {
  /** Service Contract number */
  serviceContractNumber: Scalars['String']['input'];
};

/** Filters for Services */
export const ServiceFilter = {
  /** All services */
  All: 'All',
  /** Services valid for Job creation */
  Create: 'Create',
  /** All services and addons */
  IncludeAddons: 'IncludeAddons',
  /** Services that allow for storage */
  Storage: 'Storage'
} as const;

export type ServiceFilter = typeof ServiceFilter[keyof typeof ServiceFilter];
export type ServiceInput = {
  /** See below section for more information on Questions & Answers. */
  answers?: InputMaybe<Array<QuestionAnswerInput>>;
  /** Deprecated: please use classTypeName */
  classType?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  /** Class of partner vehicle needed to complete the job */
  classTypeName?: InputMaybe<Scalars['VehicleClassTypeName']['input']>;
  /** See below section for more information on Services. */
  name?: InputMaybe<Scalars['ServiceCodeName']['input']>;
  /** Question results for the service */
  questionResults?: InputMaybe<QuestionResultsInput>;
  /** Customer can optionally schedule the job for the future. Should be in ISO 8601 format. */
  scheduledFor?: InputMaybe<Scalars['String']['input']>;
  /** Name of the Storage Site */
  storageSite?: InputMaybe<Scalars['SiteName']['input']>;
  /** Deprecated: please use storageTypeName */
  storageType?: InputMaybe<Scalars['StorageTypeName']['input']>;
  /** See below section for more information on Storage Types. */
  storageTypeName?: InputMaybe<Scalars['StorageTypeName']['input']>;
  /** See below section for more information on Symptoms. */
  symptom?: InputMaybe<Scalars['SymptomName']['input']>;
  /** Whether or not to store the customers vehicle */
  willStore?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Service Location Type Name */
export const ServiceLocationTypeEnum = {
  /** Blocking Traffic */
  BlockingTraffic: 'BlockingTraffic',
  /** Business */
  Business: 'Business',
  /** Collision Center */
  CollisionCenter: 'CollisionCenter',
  /** Dealership */
  Dealership: 'Dealership',
  /** Highway */
  Highway: 'Highway',
  /** Intersection */
  Intersection: 'Intersection',
  /** Local Roadside */
  LocalRoadside: 'LocalRoadside',
  /** Military Base */
  MilitaryBase: 'MilitaryBase',
  /** Parking Garage */
  ParkingGarage: 'ParkingGarage',
  /** Parking Lot */
  ParkingLot: 'ParkingLot',
  /** Point of Interest */
  PointOfInterest: 'PointOfInterest',
  /** Repair Facility */
  RepairFacility: 'RepairFacility',
  /** Residence */
  Residence: 'Residence',
  /** Storage Facility */
  StorageFacility: 'StorageFacility'
} as const;

export type ServiceLocationTypeEnum = typeof ServiceLocationTypeEnum[keyof typeof ServiceLocationTypeEnum];
/** Filters for Services */
export type ServicesFiltersInput = {
  /** rates related filters */
  rates?: InputMaybe<RatesFiltersInput>;
};

/** Autogenerated input type of SetCompanyRateLimit */
export type SetCompanyRateLimitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Company ID to update rate limit for. */
  companyId: Scalars['ID']['input'];
  /** Requested Rate Limit. */
  rateLimit: CompanyRateLimit;
};

/** Setting Name (key) Type */
export const SettingNameEnum = {
  /** Accepted */
  accepted: 'accepted',
  /** Active Job Search */
  active_job_search: 'active_job_search',
  /** Always Show Customer Email */
  always_show_customer_email: 'always_show_customer_email',
  /** Assigned */
  assigned: 'assigned',
  /** Auction Max Bidders */
  auction_max_bidders: 'auction_max_bidders',
  /** Auction Max Candidate Distance */
  auction_max_candidate_distance: 'auction_max_candidate_distance',
  /** Auction Max Candidate Eta */
  auction_max_candidate_eta: 'auction_max_candidate_eta',
  /** Auction Max Target Eta */
  auction_max_target_eta: 'auction_max_target_eta',
  /** Auto Delete Draft Jobs */
  auto_delete_draft_jobs: 'auto_delete_draft_jobs',
  /** Auto Delete Draft Jobs Hours */
  auto_delete_draft_jobs_hours: 'auto_delete_draft_jobs_hours',
  /** Auto Progress Status */
  auto_progress_status: 'auto_progress_status',
  /** Autocomplete Job Threshold */
  autocomplete_job_threshold: 'autocomplete_job_threshold',
  /** Billing Type */
  billing_type: 'billing_type',
  /** Complete Confirmation Alert Threshold */
  complete_confirmation_alert_threshold: 'complete_confirmation_alert_threshold',
  /** Disable Email Updates */
  disable_email_updates: 'disable_email_updates',
  /** Disable Job Alerts */
  disable_job_alerts: 'disable_job_alerts',
  /** Disable Notifications */
  disable_notifications: 'disable_notifications',
  /** Dispatch Filter */
  dispatch_filter: 'dispatch_filter',
  /** Dispatched */
  dispatched: 'dispatched',
  /** Dispatcher Filter */
  dispatcher_filter: 'dispatcher_filter',
  /** Draft */
  draft: 'draft',
  /** Drafts Tab */
  drafts_tab: 'drafts_tab',
  /** En Route */
  en_route: 'en_route',
  /** Escalations Tab */
  escalations_tab: 'escalations_tab',
  /** Eta Approaching */
  eta_approaching: 'eta_approaching',
  /** External Survey Url */
  external_survey_url: 'external_survey_url',
  /** Filter By Department */
  filterby_department: 'filterby_department',
  /** Filter By Fleet Company */
  filterby_fleet_company: 'filterby_fleet_company',
  /** Filter By Service */
  filterby_service: 'filterby_service',
  /** Filter By State */
  filterby_state: 'filterby_state',
  /** Long Dispatch */
  long_dispatch: 'long_dispatch',
  /** Max Reassign Threshold */
  max_reassign_threshold: 'max_reassign_threshold',
  /** Monitoring Tab */
  monitoring_tab: 'monitoring_tab',
  /** On Site */
  on_site: 'on_site',
  /** Past Eta */
  past_eta: 'past_eta',
  /** PCC Service Name */
  pc_cservicename: 'pc_cservicename',
  /** Pending */
  pending: 'pending',
  /** Pending Tab */
  pending_tab: 'pending_tab',
  /** Policy Lookup Service Client Code */
  policy_lookup_serviceclientcode: 'policy_lookup_serviceclientcode',
  /** Policy Lookup Service Fields Required For Coverage */
  policy_lookup_servicefieldsrequiredfor_coverage: 'policy_lookup_servicefieldsrequiredfor_coverage',
  /** Policy Lookup Service Lookup Types */
  policy_lookup_servicelookuptypes: 'policy_lookup_servicelookuptypes',
  /** Policy Number Maximum Length */
  policy_numbermaximumlength: 'policy_numbermaximumlength',
  /** Policy Number Minimum Length */
  policy_numberminimumlength: 'policy_numberminimumlength',
  /** Qb Ignore Canceled Invoices */
  qb_ignore_canceled_invoices: 'qb_ignore_canceled_invoices',
  /** Qb Sync Payments */
  qb_syncpayments: 'qb_syncpayments',
  /** Received */
  received: 'received',
  /** Recommended Dropoff Sites Bouding Box Max Distance */
  recommended_dropoff_sites_bouding_box_max_distance: 'recommended_dropoff_sites_bouding_box_max_distance',
  /** Recommended Dropoff Sites Types */
  recommended_dropoff_sites_types: 'recommended_dropoff_sites_types',
  /** Redispatch Referred Job Threshold */
  redispatch_referred_job_threshold: 'redispatch_referred_job_threshold',
  /** Require On Site */
  require_on_site: 'require_on_site',
  /** Resources Url */
  resources_url: 'resources_url',
  /** Run Coverage On New Job Form Load */
  run_coverageon_new_job_formload: 'run_coverageon_new_job_formload',
  /** Scheduled */
  scheduled: 'scheduled',
  /** Scheduled For Reminder Threshold */
  scheduled_for_reminder_threshold: 'scheduled_for_reminder_threshold',
  /** Scheduled Tab */
  scheduled_tab: 'scheduled_tab',
  /** Search Results Exceeded */
  searchresultsexceeded: 'searchresultsexceeded',
  /** Submitted */
  submitted: 'submitted',
  /** Survey Background */
  survey_background: 'survey_background',
  /** Survey Text Delay Threshold */
  survey_text_delay_threshold: 'survey_text_delay_threshold',
  /** Tow Destination */
  tow_destination: 'tow_destination',
  /** Towing */
  towing: 'towing'
} as const;

export type SettingNameEnum = typeof SettingNameEnum[keyof typeof SettingNameEnum];
/** Autogenerated input type of SimulatePartnerVehicleMovement */
export type SimulatePartnerVehicleMovementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Time to go from start location to end location */
  durationInSeconds: Scalars['Int']['input'];
  /** End location of simulation */
  endLocation: LocationInput;
  /** Start location of simulation */
  startLocation: LocationInput;
  /** Friendly Vehicle ID (Database ID) */
  swcid: Scalars['Int']['input'];
};

/** Autogenerated input type of SingleRateUpdate */
export type SingleRateUpdateInput = {
  changeReason: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rate: RateInput;
};

export type SiteCompanyInput = {
  /** Site dispatchable */
  dispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Site dispatchable by fleet */
  dispatchableByFleet?: InputMaybe<Scalars['Boolean']['input']>;
  /** Site fleet rescue provider */
  fleetRescueProvider?: InputMaybe<FleetRescueProviderInput>;
  /** Company ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Site Location. */
  location: LocationInput;
  /** Site location id */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** Site Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Site Name with company */
  nameWithCompany?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the Site. */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Site tire program */
  tireProgram?: InputMaybe<Scalars['Boolean']['input']>;
  /** Site company type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Site timezone */
  tz?: InputMaybe<Scalars['String']['input']>;
};

/** Site Enum Type */
export const SiteEnumType = {
  /** this is a site owned by a client */
  ClientSite: 'ClientSite',
  /** This is a point of interest */
  PointOfInterestSite: 'PointOfInterestSite',
  /** This is a site owned by a service provider */
  ServiceProviderSite: 'ServiceProviderSite'
} as const;

export type SiteEnumType = typeof SiteEnumType[keyof typeof SiteEnumType];
/** Sort Order Enum */
export const SortOrderEnum = {
  ASC: 'ASC',
  DESC: 'DESC'
} as const;

export type SortOrderEnum = typeof SortOrderEnum[keyof typeof SortOrderEnum];
/** Sortable dashboard column header keys. */
export const SortableColumns = {
  /** Alerts */
  Alerts: 'Alerts',
  /** Background Check Dates */
  BackgroundCheckDates: 'BackgroundCheckDates',
  /** Full Legal Name */
  BackgroundCheckFullLegalName: 'BackgroundCheckFullLegalName',
  /** Background Check Status */
  BackgroundCheckStatus: 'BackgroundCheckStatus',
  /** Company */
  Company: 'Company',
  /** Completed Date */
  CompletedDate: 'CompletedDate',
  /** Created */
  CreatedTime: 'CreatedTime',
  /** Dispatcher */
  Dispatcher: 'Dispatcher',
  /** ETA */
  Eta: 'Eta',
  /** ETA */
  Eta2: 'Eta2',
  /** ID */
  Id: 'Id',
  /** Job Id */
  JobId: 'JobId',
  /** Last Name */
  LastName: 'LastName',
  /** Partner */
  Partner: 'Partner',
  /** Status */
  Status: 'Status',
  /** Status Age */
  StatusAge: 'StatusAge',
  /** Total Time */
  TotalTime: 'TotalTime',
  /** Wait Time */
  WaitTime: 'WaitTime'
} as const;

export type SortableColumns = typeof SortableColumns[keyof typeof SortableColumns];
/** Sortable pages. */
export const SortablePages = {
  /** Dashboard */
  Dashboard: 'Dashboard',
  /** Reviews */
  Reviews: 'Reviews'
} as const;

export type SortablePages = typeof SortablePages[keyof typeof SortablePages];
/**
 * Details about the storage facility holding a vehicle.
 * This information is required to move a 'Release' job to Draft status.
 */
export type StorageYardInput = {
  /** The name of the contact person at the storage yard */
  contactName?: InputMaybe<Scalars['String']['input']>;
  /** Email address of the storage yard */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** The physical location of the storage yard */
  location: LocationInput;
  /** Name of the storage yard */
  name: Scalars['String']['input'];
  /** The contact phone number of the storage yard */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
};

/** Stranded Vehicle Input Type */
export type StrandedVehicleInput = {
  /** Vehicle color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Drivetrain */
  drivetrain?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle equipment */
  equipment?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle license */
  license?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle make */
  make?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle odometer */
  odometer?: InputMaybe<Scalars['BigInt']['input']>;
  /** Serial Number */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Stranded Vehicle Class Type */
  strandedVehicleClassType?: InputMaybe<Scalars['String']['input']>;
  /** Style */
  style?: InputMaybe<Scalars['String']['input']>;
  /** Tire Size */
  tireSize?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Total Length */
  totalLengthRange?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Total Weight */
  totalWeightRange?: InputMaybe<Scalars['String']['input']>;
  /** Unit Number */
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Type */
  vehicleType?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle vin */
  vin?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle year */
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Each value represents a group of `JobStatuses` to query on. */
export const SubscriptionJobStatusGroup = {
  /** Active Jobs */
  Active: 'Active',
  /** Done Jobs */
  Done: 'Done',
  /** Draft Jobs */
  Draft: 'Draft',
  /** Map Jobs */
  Map: 'Map'
} as const;

export type SubscriptionJobStatusGroup = typeof SubscriptionJobStatusGroup[keyof typeof SubscriptionJobStatusGroup];
/** Company Subscription Status */
export const SubscriptionStatusEnum = {
  /** Free Trial */
  FreeTrial: 'FreeTrial',
  /** Inactive */
  Inactive: 'Inactive',
  /** Network Only */
  NetworkOnly: 'NetworkOnly',
  /** Options */
  Options: 'Options',
  /** Pending Cancelation */
  PendingCancelation: 'PendingCancelation',
  /** Subscribed - Custom */
  SubscribedCustom: 'SubscribedCustom',
  /** Subscribed - Enterprise */
  SubscribedEnterprise: 'SubscribedEnterprise',
  /** Subscribed - Essential */
  SubscribedEssential: 'SubscribedEssential',
  /** Subscribed - Free */
  SubscribedFree: 'SubscribedFree',
  /** Subscribed - Premium */
  SubscribedPremium: 'SubscribedPremium',
  /** Subscribed - Professional */
  SubscribedProfessional: 'SubscribedProfessional'
} as const;

export type SubscriptionStatusEnum = typeof SubscriptionStatusEnum[keyof typeof SubscriptionStatusEnum];
/** Roles supported by available notification settings. */
export const SupportedRolesEnum = {
  /** Admin role */
  admin: 'admin',
  /** Dispatcher role */
  dispatcher: 'dispatcher',
  /** Driver role */
  driver: 'driver'
} as const;

export type SupportedRolesEnum = typeof SupportedRolesEnum[keyof typeof SupportedRolesEnum];
/** Survey Question Type */
export const SurveyQuestionType = {
  /** FiveStarQuestion */
  FiveStarQuestion: 'FiveStarQuestion',
  /** LongTextQuestion */
  LongTextQuestion: 'LongTextQuestion',
  /** TenStarQuestion */
  TenStarQuestion: 'TenStarQuestion'
} as const;

export type SurveyQuestionType = typeof SurveyQuestionType[keyof typeof SurveyQuestionType];
/** Task Status */
export const TaskStatus = {
  /** completed Task */
  Completed: 'Completed',
  /** open Task */
  Open: 'Open'
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];
export type TasksFiltersInput = {
  /** Assignee ID */
  assigneeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Client Company ID */
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Service Name */
  services?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Status of task.  Defaults to "open".  Set to "completed" when done. */
  status?: InputMaybe<TaskStatus>;
  /** User-configured dashboard filters */
  useUserFilters?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Terminal Status Sub-reason */
export const TerminalStatusSubReasonEnumType = {
  /** Customer Declined Service */
  CustomerDeclinedService: 'CustomerDeclinedService',
  /** Customer Found Solution */
  CustomerFoundSolution: 'CustomerFoundSolution',
  /** Duplicate Request */
  DuplicateRequest: 'DuplicateRequest',
  /** No Client Approval */
  NoClientApproval: 'NoClientApproval',
  /** No Customer/Client Response */
  NoCustomerOrClientResponse: 'NoCustomerOrClientResponse',
  /** Towed by Another Provider */
  TowedByAnotherProvider: 'TowedByAnotherProvider',
  /** Towed by Police */
  TowedByPolice: 'TowedByPolice',
  /** Towed by Repair Facility */
  TowedByRepairFacility: 'TowedByRepairFacility',
  /** Vehicle Unrepairable */
  VehicleUnrepairable: 'VehicleUnrepairable'
} as const;

export type TerminalStatusSubReasonEnumType = typeof TerminalStatusSubReasonEnumType[keyof typeof TerminalStatusSubReasonEnumType];
/** Tow Destination Reject Reasons */
export const TowDestinationRejectReasons = {
  /** After hours */
  AfterHours: 'AfterHours',
  /** Customer preferred */
  CustomerPreferred: 'CustomerPreferred',
  /** No answer */
  NoAnswer: 'NoAnswer',
  /** No equipment */
  NoEquipment: 'NoEquipment',
  /** No space */
  NoSpace: 'NoSpace',
  /** No staff/technician */
  NoStaffTechnician: 'NoStaffTechnician'
} as const;

export type TowDestinationRejectReasons = typeof TowDestinationRejectReasons[keyof typeof TowDestinationRejectReasons];
/** Listing all Tracking Statuses */
export const TrackingStatus = {
  location_confirmed: 'location_confirmed',
  location_page_accessed: 'location_page_accessed',
  processing: 'processing',
  request_delivered: 'request_delivered',
  request_failed: 'request_failed'
} as const;

export type TrackingStatus = typeof TrackingStatus[keyof typeof TrackingStatus];
/** Enum to specify the type of transaction for payments or credits on an invoice */
export const TransactionTypeEnum = {
  /** Discount */
  Discount: 'Discount',
  /** Payment */
  Payment: 'Payment',
  /** Refund */
  Refund: 'Refund',
  /** Write Off */
  WriteOff: 'WriteOff'
} as const;

export type TransactionTypeEnum = typeof TransactionTypeEnum[keyof typeof TransactionTypeEnum];
/** Autogenerated input type of UndeleteCompany */
export type UndeleteCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company: CompanyInput;
};

/** Autogenerated input type of UndeleteRateAgreement */
export type UndeleteRateAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Global ID Rate Agreement */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Friendly Rate Agreement ID (Database ID) */
  swcid?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UndoApproveInvoice */
export type UndoApproveInvoiceInput = {
  /** SSID of user authorizing. Required if authenticated as an application */
  approverId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the invoice being rejected. */
  id: Scalars['ID']['input'];
};

export type UnitNumberInput = {
  /** Unit number */
  unitNumber: Scalars['String']['input'];
};

/** Autogenerated input type of UnlinkParent */
export type UnlinkParentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Friendly Rescue Company ID (Database ID) */
  swcid: Scalars['Int']['input'];
};

/** Autogenerated input type of UnregisterForPushNotifications */
export type UnregisterForPushNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endpointId: Scalars['String']['input'];
};

/** Autogenerated input type of UpdateAccount */
export type UpdateAccountInput = {
  /** accounting email */
  accountingEmail?: InputMaybe<Scalars['EmailsCommaSeparated']['input']>;
  /** accounting emails to cc */
  accountingEmailBcc?: InputMaybe<Scalars['EmailsCommaSeparated']['input']>;
  /** accounting emails to cc */
  accountingEmailCc?: InputMaybe<Scalars['EmailsCommaSeparated']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** SSID of the account being updated. */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateClientCompanyRequiredEquipmentRule */
export type UpdateClientCompanyRequiredEquipmentRuleInput = {
  /** Client Company SSID */
  clientCompanyId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Equipment Name */
  equipment?: InputMaybe<Scalars['String']['input']>;
  /** Client Required Equipment Rule SSID */
  id: Scalars['ID']['input'];
  /** Vehicle Make Name */
  make?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Model Name */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Service Code Group SSID */
  serviceCodeGroupId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateClientEligibility */
export type UpdateClientEligibilityInput = {
  /** A comment about the reason this change is being made. */
  auditComment: Scalars['String']['input'];
  /** A collection of Client IDs. */
  clientIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the ClientEligibility being updated. */
  id: Scalars['ID']['input'];
  /** The policy for this ClientEligibility. */
  policy?: InputMaybe<ClientEligibilityPolicyEnum>;
};

/** Autogenerated input type of UpdateClientProgram */
export type UpdateClientProgramInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Program Code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Coverage File */
  coverageFile?: InputMaybe<CoverageFileRequiredInputFields>;
  /** Discretionary Miles */
  discretionaryMiles?: InputMaybe<Scalars['Int']['input']>;
  /** Client Program SSID */
  id: Scalars['ID']['input'];
  /** Manual Selection */
  manualSelection?: InputMaybe<Scalars['Boolean']['input']>;
  /** Client Program Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Client Program Identifier */
  programId?: InputMaybe<Scalars['String']['input']>;
  /** Rank */
  rank?: InputMaybe<Scalars['Int']['input']>;
  /** VCC Limit */
  vccLimit?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated input type of UpdateCompanyFeatures */
export type UpdateCompanyFeaturesInput = {
  addFeatures?: InputMaybe<Array<FeatureEnum>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  removeFeatures?: InputMaybe<Array<FeatureEnum>>;
};

/** Autogenerated input type of UpdateCompanyInHouseClients */
export type UpdateCompanyInHouseClientsInput = {
  addClients?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  removeClients?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated input type of UpdateCompany */
export type UpdateCompanyInput = {
  accountingEmail?: InputMaybe<Scalars['Email']['input']>;
  billingType?: InputMaybe<BillingType>;
  clientCode?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  compliance?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Currency>;
  customStatementDescriptor?: InputMaybe<Scalars['String']['input']>;
  customerExperience?: InputMaybe<CompanyCustomerExperienceInput>;
  customerLookupSource?: InputMaybe<CustomerLookupSourceEnum>;
  customerLookupTypes?: InputMaybe<Array<CustomerLookupTypeEnum>>;
  dispatchEmail?: InputMaybe<Scalars['Email']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  id: Scalars['ID']['input'];
  invoiceChargeFee?: InputMaybe<CompanyInvoiceChargeFeeInput>;
  language?: InputMaybe<LanguageEnum>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<LocationInput>;
  /** Company Name (Provider company only) */
  nameProviderOnly?: InputMaybe<Scalars['String']['input']>;
  networkManager?: InputMaybe<CompanyNetworkManagerInput>;
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  requiresApprovedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  resourcesUrl?: InputMaybe<Scalars['String']['input']>;
  ssoRedirectLink?: InputMaybe<Scalars['String']['input']>;
  subscriptionStatusEnum?: InputMaybe<SubscriptionStatusEnum>;
  supportEmail?: InputMaybe<Scalars['Email']['input']>;
  towDestinations?: InputMaybe<UpdateCompanyTowDestinations>;
};

/** Autogenerated input type of UpdateCompanyInsightsReports */
export type UpdateCompanyInsightsReportsInput = {
  addReports?: InputMaybe<Array<InsightsReportEnum>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  removeReports?: InputMaybe<Array<InsightsReportEnum>>;
};

/** Autogenerated input type of UpdateCompanyManagedClients */
export type UpdateCompanyManagedClientsInput = {
  addClients?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  removeClients?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateCompanyTowDestinations = {
  clientSitesAccessCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceRank?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of UpdateCompanyUser */
export type UpdateCompanyUserInput = {
  /** List of clients this is an approved user for */
  approvedClientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Company ID */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** CTI eligibility */
  ctiEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User ID */
  id: Scalars['ID']['input'];
  /** When to send job status emails to the user */
  jobStatusEmailFrequency?: InputMaybe<JobStatusEmailFrequencyEnum>;
  /** What job status emails to send to the user */
  jobStatusEmailPreferences?: InputMaybe<JobStatusEmailPreferencesInput>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Subscribed notification event settings for the user */
  notificationSettings?: InputMaybe<Array<NotificationSettingInput>>;
  /** Secondary phone number or pager */
  pager?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Flag to indicate if the user should be un-deleted */
  restoreUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sites the user has access to */
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Username */
  userName?: InputMaybe<Scalars['String']['input']>;
  /** Roles */
  userRoles?: InputMaybe<Array<RoleType>>;
  /** Venmo username */
  venmoUsername?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateCoverageNotes */
export type UpdateCoverageNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  programCode?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  visibleToProvider?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateDriverLocation */
export type UpdateDriverLocationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Driver GPS co-ordinates */
  driverLocation: UpdateDriverLocationInputType;
};

/** Driver GPS co-ordinates */
export type UpdateDriverLocationInputType = {
  /** SSID that corresponds to a User */
  driverId: Scalars['ID']['input'];
  /** Latitude */
  lat: Scalars['Float']['input'];
  /** Longitude */
  lng: Scalars['Float']['input'];
};

/** Autogenerated input type of UpdateEventLimit */
export type UpdateEventLimitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Time Period in Days */
  days?: InputMaybe<Scalars['Int']['input']>;
  /** Event Limit SSID */
  id: Scalars['ID']['input'];
  /** Limit covered events */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Program Code */
  programCode?: InputMaybe<Scalars['String']['input']>;
  /** Service Name */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** Target */
  target?: InputMaybe<EventLimitTarget>;
};

/** Autogenerated input type of UpdateInsightsReports */
export type UpdateInsightsReportsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  reportIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateInvoiceAttributes */
export type UpdateInvoiceAttributesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice Attributes input */
  invoiceAttributes: InvoiceAttributesInput;
};

/** Autogenerated input type of UpdateInvoice */
export type UpdateInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoice: InvoiceInput;
};

/** Autogenerated input type of UpdateInvoiceLineItem */
export type UpdateInvoiceLineItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The SSID of the invoice that the additional item is being added to */
  invoiceId: Scalars['ID']['input'];
  /** The SSID of the line item that is being updated */
  lineItemId: Scalars['ID']['input'];
  /** The price of the line item */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The quantity of the line item */
  quantity?: InputMaybe<Scalars['Float']['input']>;
  /** usage context of this mutation */
  usageContext?: InputMaybe<InvoiceUsageContextEnum>;
};

/** Autogenerated input type of UpdateInvoiceTransaction */
export type UpdateInvoiceTransactionInput = {
  /** The absolute value of the amount of the transaction */
  amount: Scalars['Float']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Date of the transaction */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /** The ID of the invoice that the transaction is being added to */
  invoiceId: Scalars['ID']['input'];
  /** A memo for the transaction */
  memo?: InputMaybe<Scalars['String']['input']>;
  /** The type of transaction that is being added to the invoice */
  paymentMethod?: InputMaybe<InvoiceTransactionPaymentMethodEnum>;
  /** The ID of the transaction that is being updated */
  transactionId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateJobFormJob */
export type UpdateJobFormJobInput = {
  /** Flag to activate job */
  activateJob?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Job to be updated from the Job Form */
  job: JobFormJobInput;
};

/** Autogenerated input type of UpdateJob */
export type UpdateJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job: UpdateJobJobInput;
};

export type UpdateJobJobInput = {
  account?: InputMaybe<AccountInput>;
  /** @deprecated This is no longer used and the value is ignored */
  autoAssignDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  caller?: InputMaybe<JobCallerInput>;
  /** Case details */
  case?: InputMaybe<CaseInputType>;
  /** Case SSID */
  caseId?: InputMaybe<Scalars['ID']['input']>;
  claim?: InputMaybe<JobClaimInput>;
  client?: InputMaybe<ClientInput>;
  clientProgram?: InputMaybe<ClientProgramInput>;
  customer?: InputMaybe<JobCustomerInput>;
  customerLookupType?: InputMaybe<Scalars['String']['input']>;
  customerPayment?: InputMaybe<JobCustomerPaymentInput>;
  /** Date of Loss */
  dateOfLoss?: InputMaybe<Scalars['Date']['input']>;
  dispatcherId?: InputMaybe<Scalars['ID']['input']>;
  eta?: InputMaybe<JobEtaInputType>;
  /** Exposure Number represents the number of times a vehicle has had a claim */
  exposureNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<JobLocationInput>;
  notes?: InputMaybe<NotesInput>;
  partner?: InputMaybe<JobPartnerInput>;
  /** Deprecated: Please use paymentTypeName instead */
  paymentType?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  /** The name of the PaymentType used on the Job */
  paymentTypeName?: InputMaybe<Scalars['PaymentTypeName']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  /** Policy number */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  priorityResponse?: InputMaybe<Scalars['Boolean']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  repairRefNumber?: InputMaybe<Scalars['String']['input']>;
  selectedPolicyDriver?: InputMaybe<SelectedPolicyDriverInput>;
  selectedPolicyVehicle?: InputMaybe<SelectedPolicyVehicleInput>;
  /** Service details */
  service?: InputMaybe<ServiceInput>;
  storageYard?: InputMaybe<StorageYardInput>;
  texts?: InputMaybe<JobTextsInput>;
  vehicle?: InputMaybe<VehicleInput>;
};

export type UpdateJobStatusBiddedEta = {
  /** Bidded ETA for JobStatus Accepted only */
  bidded?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateJobStatusDashboardValidations = {
  /** On-site time missing */
  onSiteTime?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateJobStatusDriverValidations = {
  /** Driver cannot be active on another job for GPS coordinates to be considered valid */
  alreadyActiveOnAnotherJob?: InputMaybe<Scalars['Boolean']['input']>;
  /** Driver needs to be assigned to job in order to update status */
  driverNeeded?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateJobStatus */
export type UpdateJobStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dashboardValidations?: InputMaybe<UpdateJobStatusDashboardValidations>;
  driverValidations?: InputMaybe<UpdateJobStatusDriverValidations>;
  job?: InputMaybe<UpdateJobStatusJobInput>;
};

export type UpdateJobStatusJobInput = {
  eta?: InputMaybe<UpdateJobStatusBiddedEta>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<UpdateJobStatusNotes>;
  /** Create an record of the on-site time */
  onSiteAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reason, in case required by the Status this Job is changed into */
  reason?: InputMaybe<Scalars['String']['input']>;
  referredProviderId?: InputMaybe<Scalars['ID']['input']>;
  referredRateAgreementId?: InputMaybe<Scalars['ID']['input']>;
  /** Update rescue vehicle location */
  rescueVehicleLocation?: InputMaybe<LatitudeLongitudeInput>;
  /** Job Status. */
  status: JobStatus;
};

export type UpdateJobStatusNotes = {
  /** Additional notes to be used for Rejected status */
  rejected?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for auto-assign ranking parameters. */
export type UpdateNetworkRankerAutoAssignInput = {
  /** The maximum cost for auto-assign ranking. */
  maxCost?: InputMaybe<Scalars['Int']['input']>;
  /** The maximum ETA for auto-assign ranking. */
  maxEta?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum quality for auto-assign ranking. */
  minQuality?: InputMaybe<Scalars['Int']['input']>;
};

/** Input type for brand ranking parameters. */
export type UpdateNetworkRankerBrandInput = {
  /** The brand ranking parameters. */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

/** Input type for cost ranking parameters. */
export type UpdateNetworkRankerCostInput = {
  /** The maximum cost for ranking. */
  max?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum cost for ranking. */
  min?: InputMaybe<Scalars['Int']['input']>;
  /** The weight to assign to the cost ranking. */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

/** Input type for ETA ranking parameters. */
export type UpdateNetworkRankerEtaInput = {
  /** The maximum ETA for ranking. */
  max?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum ETA for ranking. */
  min?: InputMaybe<Scalars['Int']['input']>;
  /** The weight to assign to the ETA ranking. */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated input type of UpdateNetworkRanker */
export type UpdateNetworkRankerInput = {
  /** The auto-assign ranking parameters. */
  autoAssign?: InputMaybe<UpdateNetworkRankerAutoAssignInput>;
  /** The brand ranking parameters. */
  brand?: InputMaybe<UpdateNetworkRankerBrandInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the company to update. */
  companyId: Scalars['ID']['input'];
  /** The cost ranking parameters. */
  cost?: InputMaybe<UpdateNetworkRankerCostInput>;
  /** The ETA ranking parameters. */
  eta?: InputMaybe<UpdateNetworkRankerEtaInput>;
  /** The quality ranking parameters. */
  quality?: InputMaybe<UpdateNetworkRankerQualityInput>;
};

/** Input type for quality ranking parameters. */
export type UpdateNetworkRankerQualityInput = {
  /** The maximum quality for ranking. */
  max?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum quality for ranking. */
  min?: InputMaybe<Scalars['Int']['input']>;
  /** The weight to assign to the quality ranking. */
  weight?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated input type of UpdatePartnerVehicle */
export type UpdatePartnerVehicleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  partnerVehicle: Input;
};

/** Autogenerated input type of UpdatePredraftJob */
export type UpdatePredraftJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  job: UpdateJobJobInput;
};

/** Autogenerated input type of UpdateRateAgreement */
export type UpdateRateAgreementInput = {
  /** Reason for the change */
  changeReason: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Rate Agreement to update */
  rateAgreement: RateAgreementUpdateInput;
  /** Set to TRUE to unsync the Rate Agreement */
  unsync?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateRate */
export type UpdateRateInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  rates: Array<RateInput>;
  serviceCodeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateRecommendedDropoffLocationTypes */
export type UpdateRecommendedDropoffLocationTypesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Location type Names */
  locationTypeNames: Array<Scalars['String']['input']>;
  /** Input args to perform record lookup */
  selectors: RecommendedDropoffLocationTypesSelectorType;
  /** Service Name */
  serviceName?: InputMaybe<Scalars['String']['input']>;
  /** Symptom Name */
  symptomName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateReview */
export type UpdateReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  review: UpdateReviewsInput;
};

export type UpdateReviewsInput = {
  /** Review ID */
  id: Scalars['ID']['input'];
  /** Question1 score */
  npsQuestion1?: InputMaybe<Scalars['Int']['input']>;
  /** Winback Status */
  status?: InputMaybe<ReviewStatusType>;
};

/** Autogenerated input type of UpdateSingleSignOnConnection */
export type UpdateSingleSignOnConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateSite */
export type UpdateSiteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  site: UpdateSiteSiteInput;
};

export type UpdateSiteSiteInput = {
  /** Applicable to PartnerCompany. If `true`, it means it is opened 24/7. */
  alwaysOpen?: InputMaybe<Scalars['Boolean']['input']>;
  /** TBD */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Applicable to PartnerCompany. If `true` this site will be available to receive dispatched Jobs. */
  dispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Site Email */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** Hours of operation */
  hoursOfOperation?: InputMaybe<HoursOfOperationInput>;
  id: Scalars['ID']['input'];
  /** Job status email preferences */
  jobStatusEmailPreferences?: InputMaybe<JobStatusEmailPreferencesInput>;
  location?: InputMaybe<LocationInput>;
  managerName?: InputMaybe<Scalars['String']['input']>;
  /** Name of the Site. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Night drop? */
  nightDrop?: InputMaybe<Scalars['Boolean']['input']>;
  /** Phone number of the Site. */
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  /** Referral Code */
  referralCode?: InputMaybe<Scalars['String']['input']>;
  /** Sponsor Rank */
  sponsorRank?: InputMaybe<Scalars['Int']['input']>;
  /** Vehicle make restrictions for the site */
  vehicleMakes?: InputMaybe<Array<VehicleMakeInput>>;
};

/** Autogenerated input type of UpdateSubscription */
export type UpdateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  webhookSecret?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateTask */
export type UpdateTaskInput = {
  /** SSID of user to be assigned to complete task. */
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Description of task to be completed. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Date and time when task should be completed.  Expected in ISO8601 format. */
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** SSID of task. */
  id: Scalars['ID']['input'];
  /** Status of task.  Defaults to "open".  Set to "completed" when done. */
  status?: InputMaybe<TaskStatus>;
};

/** Autogenerated input type of UpdateTowDestinationApprovalRule */
export type UpdateTowDestinationApprovalRuleInput = {
  /** Vehicle ClassType Name */
  classTypeName?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Tow Destination Approval Rule SSID */
  id: Scalars['ID']['input'];
  /** Client Program Code */
  programCode?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateUserBackgroundCheck */
export type UpdateUserBackgroundCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** When the background check was completed. */
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The full name of the user. */
  fullLegalName: Scalars['String']['input'];
  /** Status of the background check. */
  status: BackgroundCheckStatusEnum;
  /** The user ID associated with the background check. */
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  user: UpdateUserUserInput;
};

export type UpdateUserSettingFilterInput = {
  addElementIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  allSelected: Scalars['Boolean']['input'];
  deleteElementIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  key: UserFilterType;
};

/** Autogenerated input type of UpdateUserSetting */
export type UpdateUserSettingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UpdateUserSettingFilterInput>;
  sort?: InputMaybe<UpdateUserSettingSortInput>;
};

export type UpdateUserSettingSortInput = {
  ascending: Scalars['Boolean']['input'];
  column: SortableColumns;
  tab: DashboardTabType;
};

export type UpdateUserUserInput = {
  id: Scalars['ID']['input'];
  onDuty?: InputMaybe<Scalars['Boolean']['input']>;
  restoreUser?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<RoleType>>;
  vehicle?: InputMaybe<UpdateUserVehicleInput>;
};

export type UpdateUserVehicleInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateWave */
export type UpdateWaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  maxBids: Scalars['Int']['input'];
  time: Scalars['Int']['input'];
};

/** User Filter Type */
export const UserFilterType = {
  /** Dashboard Companies */
  DashboardCompanies: 'DashboardCompanies',
  /** Dashboard Departments */
  DashboardDepartments: 'DashboardDepartments',
  /** Dashboard Dispatch Sites */
  DashboardDispatchSites: 'DashboardDispatchSites',
  /** Dashboard Dispatchers */
  DashboardDispatchers: 'DashboardDispatchers',
  /** Dashboard Fleet Sites */
  DashboardFleetSites: 'DashboardFleetSites',
  /** Dashboard Services */
  DashboardServices: 'DashboardServices',
  /** Dashboard States */
  DashboardStates: 'DashboardStates',
  /** Review Companies */
  ReviewCompanies: 'ReviewCompanies',
  /** Review Nps Scores */
  ReviewNpsScores: 'ReviewNpsScores',
  /** Review Statuses */
  ReviewStatuses: 'ReviewStatuses',
  /** Task Dispatchers */
  TaskDispatchers: 'TaskDispatchers',
  /** Task Fleet Companies */
  TaskFleetCompanies: 'TaskFleetCompanies',
  /** Task Services */
  TaskServices: 'TaskServices',
  /** Task States */
  TaskStates: 'TaskStates'
} as const;

export type UserFilterType = typeof UserFilterType[keyof typeof UserFilterType];
/** Filters to apply when searching for users */
export type UserFiltersInput = {
  /** Filter by background check status */
  backgroundCheckStatuses?: InputMaybe<Array<BackgroundCheckStatusEnum>>;
  /** Filter by company ID */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by users who can be dispatchers on jobs */
  dispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include soft-deleted users */
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include external users (Towbook/External, for example) */
  includeExternal?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter on users that are on duty or not on duty */
  onDuty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by role */
  roles?: InputMaybe<Array<RoleType>>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['E164PhoneNumber']['input']>;
  roles?: InputMaybe<Array<RoleType>>;
};

/** Sorting to apply when searching for users */
export type UserSortInput = {
  /** Sort in ascending order */
  ascending?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sort by a sortable column */
  column?: InputMaybe<SortableColumns>;
};

/** VIN Decoder errors. */
export const VINDecoderError = {
  /** Invalid VIN */
  InvalidVIN: 'InvalidVIN',
  /** Unrecognized Error */
  UnrecognizedError: 'UnrecognizedError',
  /** Unsuccessful */
  Unsuccessful: 'Unsuccessful',
  /** VIN Not Found */
  VINNotFound: 'VINNotFound'
} as const;

export type VINDecoderError = typeof VINDecoderError[keyof typeof VINDecoderError];
export type VINInput = {
  /** VIN */
  vin: Scalars['String']['input'];
};

/** Job form vehicle field input */
export type VehicleFieldStatesInput = {
  /** Company ID */
  companyId: Scalars['ID']['input'];
  /** Service Code Name */
  serviceCode?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle details */
  vehicle?: InputMaybe<VehicleInput>;
};

export type VehicleInput = {
  /** Vehicle color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Drivetrain */
  drivetrain?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle license */
  license?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle make */
  make?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle odometer */
  odometer?: InputMaybe<Scalars['BigInt']['input']>;
  /** Serial Number */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Style */
  style?: InputMaybe<Scalars['String']['input']>;
  /** Tire Size */
  tireSize?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Total Length */
  totalLengthRange?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Total Weight */
  totalWeightRange?: InputMaybe<Scalars['String']['input']>;
  /** Unit Number */
  unitNumber?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle Type */
  vehicleType?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle vin */
  vin?: InputMaybe<Scalars['String']['input']>;
  /** Vehicle year */
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleLocationInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type VehicleMakeInput = {
  /** vehicle make ID */
  id: Scalars['ID']['input'];
};

/** Vehicle Subtype Enum */
export const VehicleSubtypeEnumType = {
  /** Boat Trailer */
  BoatTrailer: 'BoatTrailer',
  /** Bus Conversion */
  BusConversion: 'BusConversion',
  /** Car Trailer */
  CarTrailer: 'CarTrailer',
  /** Cargo Trailer */
  CargoTrailer: 'CargoTrailer',
  /** Class A */
  ClassA: 'ClassA',
  /** Class B */
  ClassB: 'ClassB',
  /** Class C */
  ClassC: 'ClassC',
  /** Fifth Wheel */
  FifthWheel: 'FifthWheel',
  /** Livestock Trailer */
  LivestockTrailer: 'LivestockTrailer',
  /** Other */
  Other: 'Other',
  /** Popup Camper */
  PopupCamper: 'PopupCamper',
  /** Sport Trailer */
  SportTrailer: 'SportTrailer',
  /** Super C */
  SuperC: 'SuperC',
  /** Toterhome */
  Toterhome: 'Toterhome',
  /** Travel Trailer */
  TravelTrailer: 'TravelTrailer',
  /** Truck Camper */
  TruckCamper: 'TruckCamper',
  /** Utility Trailer */
  UtilityTrailer: 'UtilityTrailer'
} as const;

export type VehicleSubtypeEnumType = typeof VehicleSubtypeEnumType[keyof typeof VehicleSubtypeEnumType];
/** The 7 days of the week */
export const WeekdayEnum = {
  Friday: 'Friday',
  Monday: 'Monday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Thursday: 'Thursday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday'
} as const;

export type WeekdayEnum = typeof WeekdayEnum[keyof typeof WeekdayEnum];